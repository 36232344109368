import React from "react";
import { TooltipPanel } from "../lib/Panel";
import { Box } from "@material-ui/core";
import { toCurrency } from "../../utils";

export default function Metrics({ metrics }) {
  return (
    <>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total Orders"
          value={metrics.ordersTotalCount}
          tooltip="Total number of orders opened (including canceled, refunded, etc)"
          color="#304473"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          tooltip="Orders that were opened but cancelled before completion of payment (can include orders that were partially paid)"
          label="Canceled Orders"
          value={metrics.ordersCancelCount}
          color="#12616D"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total sales (Gross)"
          tooltip="Gross sales (excluding refunds and cancelled orders)"
          value={toCurrency(metrics.incomeTotalAmount)}
          color="#30855E"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total sales (Net)"
          tooltip="Net sales (excluding refunds and cancelled orders)"
          value={toCurrency(metrics.netAmount)}
          color="#30855E"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="total sales Tax"
          tooltip="Total sales tax calculated as the sum of tax per item"
          value={toCurrency(metrics.vatAmount)}
          color="#304473"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total Discount"
          tooltip="Total discount"
          value={toCurrency(metrics.discountAmount)}
          color="#12616D"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total Treats"
          tooltip="Total amounts that were given free of charge"
          value={toCurrency(metrics.treatsAmount)}
          color="#3F3771"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total Cash"
          tooltip="Total cash payments excluding refunds and tips"
          value={toCurrency(metrics.cashAmount)}
          color="#304473"
        />
      </Box>
      <Box flexGrow={1} flexBasis={150}>
        <TooltipPanel
          label="Total Credit"
          tooltip="Total credit payments excluding refunds and tips"
          value={toCurrency(metrics.creditAmount)}
          color="#12616D"
        />
      </Box>
    </>
  );
}
