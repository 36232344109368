import React from "react";
import UIDialog from "../lib/UIDialog";
import { timeConvert, toCurrency, toTime } from "../../utils";
import { InfoPanel, TooltipPanel } from "../lib/Panel";
import { strings } from "../../Constants";
import DialogContent from "@material-ui/core/DialogContent";

export default props => {
  const { open, handleClose, user } = props;

  const shift = React.useMemo(
    () => (prop, isCurrency) => {
      return isCurrency
        ? toCurrency(user.shiftMetrics[prop])
        : user.shiftMetrics[prop];
    },
    [user]
  );
  return (
    <UIDialog
      open={open}
      handleClose={handleClose}
      secondaryAction={handleClose}
      maxWidth="lg"
      fullWidth={true}
      title={"Overview"}
    >
      <DialogContent>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: 2 }}>
            <InfoPanel
              color="#304473"
              label="Display Name"
              value={user.userDisplayName}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_SHIFT_DURATION}
              color="#304473"
              label={strings.LABEL_WAITER_SHIFT_DURATION}
              value={timeConvert(user.shiftMetrics.shiftDuration) || 0}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_DAILY_PAY}
              color="#30855E"
              label={strings.LABEL_WAITER_DAILY_PAY}
              value={shift("dailyPay", true)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_TOTAL_BREAK_DURATION}
              color="#304473"
              label={strings.LABEL_WAITER_TOTAL_BREAK_DURATION}
              value={shift("totalBreaksDuration")}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_ORDER_COUNT}
              color="#12616D"
              label={strings.LABEL_METRIC_ORDERS_COUNT}
              value={shift("orderCount")}
            />
          </div>
          {/*<div style={{ flex: 1 }}>*/}
          {/*  <TooltipPanel*/}
          {/*    tooltip={strings.TOOLTIP_WAITER_OPEN_ORDERS}*/}
          {/*    color="#5CB5AB"*/}
          {/*    label={strings.LABEL_WAITER_OPEN_ORDERS}*/}
          {/*    value={openOrders.length}*/}
          {/*  />*/}
          {/*</div>*/}
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_TOTAL_GROSS}
              color="#30855E"
              label={strings.LABEL_WAITER_TOTAL_GROSS}
              value={shift("totalAmount", true)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              color="#304473"
              tooltip={strings.TOOLTIP_WAITER_TOTAL_OFFER}
              label={strings.LABEL_WAITER_TOTAL_OFFER}
              value={shift("offerAmount", true)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_CHECK_IN_CASH}
              color="#30855E"
              label={strings.LABEL_WAITER_CHECK_IN_CASH}
              value={shift("checkInCash", true)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              tooltip={strings.TOOLTIP_WAITER_CHECK_OUT_CASH}
              color="#30855E"
              label={strings.LABEL_WAITER_CHECK_OUT_CASH}
              value={shift("checkOutCash", true)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TooltipPanel
              color="#12616D"
              tooltip={strings.TOOLTIP_WAITER_CHECK_IN_AT}
              label={strings.LABEL_WAITER_CHECK_IN_AT}
              value={
                user.shiftMetrics.checkInAt
                  ? toTime(new Date(user.shiftMetrics.checkInAt))
                  : "N/A"
              }
            />
          </div>
        </div>
      </DialogContent>
    </UIDialog>
  );
};
