import React from 'react'
import { Bar } from 'react-chartjs-2';
// import Hammer from 'hammerjs';
// // import zoom from 'chartjs-plugin-zoom'
// import * as zoom from 'chartjs-plugin-zoom';

import { makeStyles, Grid } from '@material-ui/core';

require('chartjs-plugin-zoom')
require('hammerjs')

const colors = ["#7C359D", "#3F3771", "#F4D63A", "#1E8999", "#ec407a", "#33B378", "#384354", "#ff7961", "#6f79a8", "#26c6da"]

const useStyles = makeStyles(({
    paper: {
        padding: "8px",
        flexGrow: 1,
        backgroundColor: '#F7F7F8',
        // maxHeight: 250,
        // overflow: 'auto'
        height: 300,
        maxHeight: 300,
        minHeight: 300
    },
    title: {
        padding: "16px",
        color: '#181D30',
        fontSize: "12px",
        opacity: .5,
        textTransform: "uppercase",
        letterSpacing: "1px",
        borderBottom: "1px solid #E5E6E8"
    },
}));

const BarChart = ({ data, labels, title, formatter }) => {

    let pallet = []
    while (pallet.length < data.length) {
        pallet = [
            ...pallet,
            ...colors
        ]
    }

    const classes = useStyles();
    // #7C359D", "#3F3771", "#F4D63A"

    const datasets = data.map((set, index) => {
        return {
            label: set.label,
            backgroundColor: pallet[index],
            borderColor: pallet[index],
            borderWidth: 2,
            hoverBackgroundColor: "#fff",
            hoverBorderColor: pallet[index],
            data: set.data
        }
    })

    const chartData = {
        labels,
        datasets: datasets
    }



    const options = {
        pan: {
            enabled: true,
            mode: 'x'
        },
        zoom: {
            enabled: true,
            mode: 'x'
        },
        legend: {
            display: true,
            variant: "bullet-primary"
        },
        plugins: {
            datalabels: {
                display: false,
                anchor: "end",
                align: "end",
                fontSize: 14,
                color: '#000',
                formatter: function (value, context) {
                    return formatter ? formatter(value) : value;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    // var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    // var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return formatter ? formatter(currentValue) : currentValue;

                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        scales: {
            yAxes: [{
                display: true,
                position: 'left',
                ticks: {
                    min: 0,
                    beginAtZero: true
                },
                // gridLines: {
                //     color: "rgba(0, 0, 0, 0)",
                // }
            }],
            xAxes: [{
                // gridLines: {
                //     color: "rgba(0, 0, 0, 0)",
                //     offsetGridLines: true
                // },
                // barThickness: 25,  // number (pixels) or 'flex'
                maxBarThickness: 20 // number (pixels)
            }]
        },
        maintainAspectRatio: false,

    }

    return (
        <div className={[classes.paper, "stats-chart"].join(" ")} >
            {/* <div className={classes.title}>Orders per hour</div> */}
            <Bar
                responsive={true}
                data={chartData}
                options={options}
                height={200}
            />
        </div>
    );

}


const useStyles2 = makeStyles(({
    title: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        display: "flex",
        lineHeight: 2,
        alignItems: "center",
        color: '#384354'
    },
    description: {
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: 2,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: '#181D30'
    },
    cellFirst: {
        padding: "8px 8px 8px 16px"
    },
    cellMiddle: {
        padding: "8px 8px"
    },
    cellLast: {
        padding: "8px 16px 8px 8px"
    },
    cellBorder: {
        borderBottomColor: "#E5E6E8"
    },
    cellHead: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        alignItems: "center",
        letterSpacing: 1,
        textTransform: "uppercase"
    }
}));

export default function MultiBar(props) {
    const classes = useStyles2()

    const { labels, data, title, description, formatter } = props

    return (
        <div style={{ overflow: 'auto', width: 100 + '%' }}>
            <Grid container alignItems="center" justify="space-between" style={{ marginBottom: 16 }}>
                <Grid item>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                </Grid>
            </Grid>
            <div className="chartWrapper">
                <div className="chartAreaWrapper">
                    <BarChart
                        title={title}
                        data={data}
                        formatter={formatter}
                        labels={labels}
                    />
                </div>
            </div>
        </div>
    )
}
