import React, { useRef, useEffect, useState } from "react";
import { Map, ZoomControl, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { postData, toCurrency, unwrap } from "../../utils";
import { ENDPOINT } from "../../config";
import BusyIndicator from "../lib/BusyIndicator";
import { Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  card: {
    minWidth: 300
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

const panelStyles = makeStyles({
  root: {
    backgroundColor: props => props.color + "!important",
    color: props => (props.textColor || "#fff") + "!important",
    flexGrow: 1,
    margin: "4px",
    padding: "8px 10px",
    borderRadius: "10px"
  },
  label: {
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed!Important",
    opacity: 0.7,
    fontSize: "8px",
    letterSpacing: "1px",
    marginBottom: "4px"
  },
  value: {
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Roboto Mono"
  }
});

const MapPanel = props => {
  const classes = panelStyles(props);

  return (
    <Paper className={classes.root}>
      <div className={classes.label}>{props.label}</div>
      <div className={classes.value}>{props.value}</div>
    </Paper>
  );
};

const safePosition = [40.758896, -73.98513];

export default function Maps({ stores, position }) {
  const classes = useStyles();
  const [metrics, setMetrics] = useState([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    setBusy(true);
    const promises = stores.map(store =>
      postData(`${ENDPOINT.REPORTS}/metrics?storeId=${store.id}`)
    );
    Promise.all(promises)
      .then(data => {
        setMetrics(data);
        setBusy(false);
      })
      .catch(ex => {
        console.log(ex);
        setBusy(false);
      });
  }, [stores]);

  const invalidate = useRef();

  const getMarkers = () => {
    const customMarker = L.icon({
      iconUrl: require("../../assets/icons/ico-home.svg")
    });

    return stores
      .filter(store => {
        return store.latitude !== null && store.longitude !== null;
      })
      .map((store, index) => {
        const storeMetrics = metrics[index];

        return (
          <Marker
            icon={customMarker}
            key={store.id}
            position={[store.latitude, store.longitude]}
          >
            <Tooltip permanent={true}>
              <Grid container className={classes.card}>
                <BusyIndicator busy={busy} />
                <Grid item xs={12}>
                  <Typography
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    {store.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <MapPanel
                    color="#304473"
                    label="Orders"
                    value={unwrap(storeMetrics, "ordersTotalCount") || 0}
                  />
                </Grid>
                <Grid item>
                  <MapPanel
                    color="#30855E"
                    label="Gross"
                    value={toCurrency(
                      unwrap(storeMetrics, "incomeTotalAmount")
                    )}
                  />
                </Grid>
                <Grid item>
                  <MapPanel
                    color="#30855E"
                    label="Net"
                    value={toCurrency(unwrap(storeMetrics, "netAmount"))}
                  />
                </Grid>
              </Grid>
            </Tooltip>
          </Marker>
        );
      });
  };

  return (
    <>
      <Map
        style={{ height: "100vh" }}
        useFlyTo={true}
        zoomControl={false}
        ref={invalidate}
        // whenReady={() => loadMarkers()}
        // onZoomEnd={(e) => handleZoomEnd()}
        // onMoveEnd={(e) => handleMoveEnd()}
        // onViewReset={(e) => handleViewReset()}
        center={position || safePosition}
        zoom={15}
        maxZoom={99}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          attribution="&copy; 2019 Snapserve"
          keepBuffer={10}
          url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2thcnlvZnlsbGlzIiwiYSI6ImNqcnc3MW50MTA4dGI0OW9hZm43cHRkOGIifQ.KnOoWIU0eSY3dydyuE4ivw"
        />
        {getMarkers()}
      </Map>
    </>
  );
}
