import React, { useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, Switch, Grid } from '@material-ui/core';
import 'chartjs-plugin-datalabels';
import ReportTable from './ReportTable';

const colors = ["#7C359D", "#3F3771", "#F4D63A", "#1E8999", "#ec407a", "#33B378", "#384354", "#ff7961", "#6f79a8", "#26c6da"]

const useStyles = makeStyles(({
    title: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        display: "flex",
        lineHeight: 2,
        alignItems: "center",
        color: '#384354'
    },
    description: {
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: 2,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: '#181D30'
    }
}));

export default function TableDoughnut(props) {
    const classes = useStyles()

    const { labels, data, title, description, tableHead, tableBody, legendPosition = 'right', formatter } = props
    const [showPie, setShowPie] = useState(true)

    const _data = {
        labels,
        datasets: [{
            data,
            backgroundColor: colors.slice(0, data.length)
        }]
    };

    const handleChange = () => {
        setShowPie(!showPie);
    };

    const options = {
        responsive: true,
        cutoutPercentage: 70,
        maintainAspectRatio: false,
        legend: {
            position: legendPosition
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                formatter: function (value, context) {
                    return formatter ? formatter(value) : value;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    // var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    // var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return formatter ? formatter(currentValue) : currentValue;

                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }


    return (
        <div style={{ overflow: 'auto', width: 100 + '%' }} >
            <Grid container alignItems="center" justify="space-between" style={{ marginBottom: 16 }}>
                <Grid item>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                </Grid>
                {tableBody && <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" style={{ opacity: showPie ? 0.4 : 1 }} src={require('../../assets/icons/ico-grid.svg')} />
                    <Switch
                        checked={showPie}
                        onChange={handleChange}
                        value="showPie"
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <img alt="" style={{ opacity: showPie ? 1 : 0.4 }} src={require('../../assets/icons/ico-pie.svg')} />
                </Grid>}
            </Grid>

            {
                showPie ?
                    <div style={{ height: "200px" }}>
                        <Doughnut
                            options={options}
                            data={_data}
                        />
                    </div>
                    : <ReportTable
                        tableBody={tableBody}
                        tableHead={tableHead}
                    />
            }

        </div>
    )
}
