import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { getCredentials, unwrap, updateData } from "../../utils";
import UIDialog from "../lib/UIDialog";
import { ENDPOINT } from "../../config";

const md5 = require("md5");

const me = getCredentials("user");

const EditProfile = React.memo(props => {
  const { open, handleClose, users, fetchUsers } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const user = users.find(x => x.id === me.id);

  function updateUser(user, data) {
    setBusy(true);
    updateData(ENDPOINT.USERS + "/" + user.id + '/credentials', data)
      .then(user => {
        fetchUsers();
        setBusy(false);
        handleClose();
      })
      .catch(ex => {
        setBusy(false);
      });
  }

  useEffect(() => {
    setUsername(unwrap(user, "username"));
    setPassword("");
  }, [user, open]);

  const onUpdate = () => {
    const hash = md5(password);
    const data = {
      username: user.username,
      password: hash
    }
    updateUser(user, data);
  };

  return (
    <UIDialog
      handleClose={handleClose}
      open={open}
      primaryAction={onUpdate}
      primaryTitle="Save"
      title="Edit profile"
      fullWidth={true}
      maxWidth={"xs"}
      busy={busy}
      isValid={username !== "" && password !== ""}
      secondaryAction={handleClose}
    >
      <Grid container>
        <Grid item sm={12}>
          <TextField
            className="w-100"
            disabled
            value={username}
            onChange={e => setPassword(e.target.value)}
            label="User name"
            type="text"
            name="text"
            margin="normal"
            variant="filled"
            color={"secondary"}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            autoFocus
            className="w-100"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label={"Password"}
            type="password"
            name="password"
            margin="normal"
            variant="filled"
            color={"secondary"}
          />
        </Grid>
      </Grid>
    </UIDialog>
  );
});

export default EditProfile;
