import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles({
  paper: {
    padding: "8px",
    flexGrow: 1,
    // maxHeight: 250,
    // overflow: 'auto'
    height: 278,
    maxHeight: 278,
    minHeight: 278
  },
  title: {
    padding: "16px",
    fontSize: "12px",
    opacity: 0.5,
    textTransform: "uppercase",
    letterSpacing: "1px",
    borderBottom: "1px solid #E5E6E8"
  }
});

const Statistics = ({ data }) => {
  const classes = useStyles();
  // #7C359D", "#3F3771", "#F4D63A"
  const chartData = {
    labels: data.map(x => x.label.split("-")[0]),
    datasets: [
      {
        type: "bar",
        label: "Orders per hour",
        backgroundColor: "#fff",
        borderColor: "#3F3771",
        borderWidth: 2,
        hoverBackgroundColor: "#7C359D",
        hoverBorderColor: "#7C359D",
        data: data.map(x => x.value)
      }
    ]
  };
  const options = {
    legend: {
      display: false,
      variant: "bullet-primary"
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: "end",
        align: "end",
        fontSize: 14,
        color: "#000",
        formatter: function(value, context) {
          return value ? value : "";
        }
      }
    },
    tooltips: {
      enabled: true,
      mode: "x"
    },
    scales: {
      yAxes: [
        {
          display: true,
          position: "left",
          ticks: {
            min: 0,
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            offsetGridLines: true
          },
          barThickness: 20, // number (pixels) or 'flex'
          maxBarThickness: 20 // number (pixels)
        }
      ]
    },
    maintainAspectRatio: false
  };

  return (
    <Paper className={[classes.paper, "stats-chart"].join(" ")}>
      {/* <div className={classes.title}>Orders per hour</div> */}
      <Bar responsive={true} data={chartData} options={options} height={200} />
    </Paper>
  );
};

export default Statistics;
