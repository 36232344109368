import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { percFormatter } from '../../utils'
import TableDoughnut from './TableDoughnut';

const useStyles = makeStyles(({
    paper: {
        padding: 16,
    }
}));

export default function Payments({
    useUsers,
    useTerminals,
    usePaymentMethods,
}) {
    const classes = useStyles()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableDoughnut
                            title="Payments per method"
                            formatter={percFormatter}
                            tableHead={usePaymentMethods.tableHead}
                            tableBody={usePaymentMethods.tableBody}
                            data={usePaymentMethods.data}
                            labels={usePaymentMethods.labels} />
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableDoughnut
                            formatter={percFormatter}
                            title="Payments per terminal"
                            data={useTerminals.data}
                            tableHead={useTerminals.tableHead}
                            tableBody={useTerminals.tableBody}
                            labels={useTerminals.labels} />
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableDoughnut
                            formatter={percFormatter}
                            title="Payments per user"
                            data={useUsers.data}
                            tableHead={useUsers.tableHead}
                            tableBody={useUsers.tableBody}
                            labels={useUsers.labels} />
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}
