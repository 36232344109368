import React, {useEffect, useState} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Origin from "./Components/Main";
import {MuiThemeProvider} from "@material-ui/core";
import {ENDPOINT, HOME_PAGE} from "./config";
import Login from "./Components/Login/Index";
import {fetchData} from "./utils";
import PrivateRoute from "./Components/lib/PrivateRoute";
import Loader from "./Components/lib/Loader";
import "./App.scss";
import {useAppTheme} from "./Constants/theme";

function App(props) {
  const appTheme = useAppTheme();
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData(ENDPOINT.OPTIONS + "?k=store.mode.currency")
      .then(data => {
        localStorage.setItem(
          `pos_setup_options${HOME_PAGE}`,
          JSON.stringify(data)
        );
        setIsAuth(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <MuiThemeProvider theme={appTheme}>
      {isLoading ? (
        <Loader />
      ) : (
        <BrowserRouter basename={HOME_PAGE}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to={`/pos`} />} />
            <PrivateRoute
              {...props}
              path={`/pos`}
              permissions={[isAuth]}
              component={props => <Origin {...props} />}
            />
            <Route
              {...props}
              path={`/login`}
              component={props => <Login isAuth={isAuth} {...props} />}
            />
          </Switch>
        </BrowserRouter>
      )}
    </MuiThemeProvider>
  );
}

export default App;
