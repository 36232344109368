import React from 'react'
import { makeStyles, Table, TableBody, TableRow, TableHead, TableCell } from '@material-ui/core';

const useStyles = makeStyles(({
    cellFirst: {
        padding: "8px 8px 8px 16px"
    },
    cellMiddle: {
        padding: "8px 8px"
    },
    cellLast: {
        padding: "8px 16px 8px 8px"
    },
    cellBorder: {
        borderBottomColor: "#E5E6E8"
    },
    cellHead: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        alignItems: "center",
        letterSpacing: 1,
        textTransform: "uppercase"
    }
}));


export default function ReportTable(props) {
    const classes = useStyles()
    const { tableHead, tableBody } = props
    return (
        <div style={{ maxHeight: 368, overflow: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            tableHead.map((header, index) => {
                                return <TableCell key={index} className={[classes.cellMiddle, classes.cellBorder, classes.cellHead].join(' ')}>{header}</TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        tableBody.map((row, index) => {
                            const cells = []
                            for (let cell in row) {
                                cells.push(row[cell])
                            }
                            return <TableRow key={index}>
                                {cells.map((cell, i) => {
                                    return <TableCell key={i} className={[classes.cellMiddle, classes.cellBorder].join(' ')}>{cell}</TableCell>
                                })}
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    )
}
