import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, TextField, Box } from '@material-ui/core';
import Moment from 'react-moment';
import { toCurrency, unwrap } from '../../utils';

export const ORDER_TYPE = {
    DINE_IN: 1,
    TAKE_AWAY: 2,
    DELIVERY: 3,
    CATERING: 4
}


export const PAYMENT_STATUS = {
    PENDING: 0,
    PAID: 1,
    CLOSED: 2,
    REFUND_PENDIND: 4,
    TIP_SET: 3,
    REFUNDED: 5,
    DECLINED: 6,
    FAILED: 7,
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontFamily: "Roboto Condensed",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        height: 40,
        color: "#3A415B"
    },
    secondaryHeading: {
        color: "#181D30",
        textTransform: "uppercase",
        letterSpacing: "1px",
        fontSize: "11px",
        opacity: .5,
    },
    search: {
        fontFamily: "Roboto",
        flexGrow: 1,
        borderRadius: "5px",
        color: "#2C3249",
        width: "100%"
    }
}));


const getOrderType = (type) => {
    switch (type) {
        case 1: return 'Table'
        case 2: return 'Takeaway'
        case 3: return 'Delivery'
        case 4: return 'Catering'
        default: return "-"
    }
}

const getPaymentMethod = (method) => {
    switch (method) {
        case 1: return 'Cash'
        case 2: return 'Credit Card'
        case 3: return 'External Payment'
        case 4: return 'Coupon'
        case 5: return 'Loyalty'
        default: return "-"
    }
}

// TODO: Lookup to workflow
const getStatusText = (status, isCanceled, isRefunded) => {
    if (isRefunded) return "Refunded"
    if (isCanceled) return "Canceled"
    switch (status) {
        case 0: return 'Open'
        case 1: return 'Printed'
        case 2: return 'Billed'
        case 3: return 'Paid'
        case 4: return 'Closed'
        default: return '-'
    }
}

const getPaymentStatusText = (status) => {
    switch (status) {
        case PAYMENT_STATUS.CLOSED: return 'Closed'
        case PAYMENT_STATUS.DECLINED: return 'Declined'
        case PAYMENT_STATUS.PAID: return 'Paid'
        case PAYMENT_STATUS.PENDING: return 'Pending'
        case PAYMENT_STATUS.REFUNDED: return 'Refunded'
        case PAYMENT_STATUS.REFUND_PENDIND: return 'Pending refund'
        case PAYMENT_STATUS.TIP_SET: return 'Tip set'
        case PAYMENT_STATUS.FAILED: return 'Failed'
        default: return '-'
    }
}


export default function OrderView({ orders, stores }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [query, setQuery] = React.useState("")
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className={classes.root}>
            <Box p={1} mb={1}>
                <TextField
                    className={classes.search}
                    id="form-search-orders"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    label={"Search for order by id..."}
                    type={"search"}
                    name="query"
                    margin="none"
                    variant="standard"
                />
            </Box>

            {
                orders
                    .filter(x => [x.orderDate.id].find(value => unwrap(value).toLowerCase().includes(query.toLowerCase())))
                    .map(storeOrder => {

                        const { orderDate } = storeOrder
                        const { orderDetails, orderPayments } = orderDate
                        const store = stores.find(x => x.id === storeOrder.storeId)
                        return (
                            <ExpansionPanel
                                key={orderDate.id}
                                expanded={expanded === orderDate.id}
                                onChange={handleChange(orderDate.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    style={{ backgroundColor: "#fff" }}
                                    id="panel1bh-header"
                                >
                                    <Grid container>

                                        {/* ORDER ID */}
                                        <Grid item sm={1} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>ORDER ID</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{orderDate.id}</Typography>
                                            </Grid>
                                        </Grid>

                                        {/* STORE */}
                                        <Grid item sm={2} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>STORE</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{unwrap(store, 'name')}</Typography>
                                            </Grid>
                                        </Grid>
                                        {/* ORDER TYPE */}
                                        <Grid item sm={2} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>TYPE</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{getOrderType(orderDate.orderType)}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={1} container justify="center" direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>TABLE</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>#{unwrap(orderDate, 'tableCode')}</Typography>
                                            </Grid>
                                        </Grid>

                                        {/* OPENED AT*/}
                                        <Grid item sm={2} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>OPENED AT</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}><Moment fromNow>{(new Date(orderDate.openedAt))}</Moment></Typography>
                                            </Grid>
                                        </Grid>

                                        {/* STATUS*/}
                                        <Grid item sm={1} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>ORDER STATUS</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{getStatusText(orderDate.status, orderDate.canceledBy, orderDate.refundedBy)}</Typography>
                                            </Grid>
                                        </Grid>

                                        {/* CUSTOMER */}
                                        <Grid item sm={2} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>CUSTOMER</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{orderDate.customerName}</Typography>
                                            </Grid>
                                        </Grid>

                                        {/* AMOUNT */}
                                        <Grid item sm={1} container direction="column">
                                            <Grid item>
                                                <Typography className={classes.secondaryHeading}>AMOUNT</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.heading}>{toCurrency(orderDate.amountTotal)}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ flexDirection: "column" }}>
                                    <Grid container spacing={2} style={{ marginTop: 8 }}>
                                        <Grid item lg={6} md={12} style={{ overflow: "auto" }}>
                                            <Table style={{ backgroundColor: "#fff" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell colSpan="6">PAYMENTS</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Tip</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        orderPayments.map(payment => {
                                                            return <TableRow key={payment.id}>
                                                                <TableCell>
                                                                    {'#' + payment.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {toCurrency(payment.amount)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {getPaymentMethod(payment.paymentMethod)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {getPaymentStatusText(payment.status)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {payment.tip || "-"}
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }

                                                </TableBody>
                                            </Table >
                                        </Grid>
                                        <Grid item lg={6} md={12} style={{ overflow: "auto" }}>
                                            <Table style={{ backgroundColor: "#fff" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell colSpan="3">ORDER ITEMS</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Item</TableCell>
                                                        <TableCell>Quantity</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        orderDetails.map(item => {
                                                            return <TableRow key={item.id}>
                                                                <TableCell>
                                                                    {item.itemName}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.quantity}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {toCurrency(item.amountTotal)}
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }

                                                </TableBody>
                                            </Table >
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
            }

        </div >
    );
}