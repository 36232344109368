import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import {
  Fab,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import { fetchData, poll, postData } from "../../utils";
import { ENDPOINT } from "../../config";
import Waiters from "./Waiters";
import Box from "@material-ui/core/Box";
import Statistics from "./Statistics";
import Metrics from "../Reports/Metrics";
import UserOverview from "./UserOverview";

const useStyles = makeStyles({
  paper: {
    padding: 16
  },
  fab: {
    backgroundColor: "white"
  },
  button: {
    fontSize: 18
  },
  search: {
    padding: 8
  },
  chartContainer: {
    // height: 'auto',
    // width: "100%"
    // flex: 1
  },
  select: {
    minWidth: 200
  },
  gridIdem: {
    // display: "flex"
  },
  flexItem: {
    flex: 1
  },
  floatButton: {
    position: "fixed",
    right: 0,
    bottom: 0,
    margin: 16
  },
  title: {
    padding: "0 8px",
    fontSize: "12px",
    opacity: 0.5,
    textTransform: "uppercase",
    letterSpacing: "1px",
    lineHeight: "26px"
  }
});

function Dashboard({ stores }) {
  const classes = useStyles();
  const [userReports, setUserReports] = useState([]);
  const [openOverview, setOpenOverview] = useState(false);
  const [user, setUser] = useState({
    userId: 11,
    userDisplayName: "",
    isCheckedIn: true,
    isInBreak: false,
    canCheckIn: false,
    canCheckInMessage: "",
    shiftMetrics: {
      id: 0,
      userId: 0,
      workDate: "",
      orderCount: 0,
      offerCount: 0,
      cancelCount: 0,
      totalAmount: 0.0,
      paymentsAmount: 0.0,
      offerAmount: 0.0,
      breaks: "",
      totalBreaksDuration: 0,
      shiftDuration: 0,
      rateHourly: 0.0,
      dailyPay: 0.0,
      checkInCash: 0.0,
      checkInAt: null,
      checkOutCash: 0.0,
      checkOutAt: null
    }
  });
  const [busy, setBusy] = useState(false);
  const [hourlyData, setHourlyData] = useState([]);
  const [counter, setCounter] = useState(1);
  const [storeId, setStoreId] = useState(0);
  const [metrics, setMetrics] = useState({
    cashAmount: 0,
    creditAmount: 0,
    discountAmount: 0,
    incomeTotalAmount: 0,
    netAmount: 0,
    ordersCancelCount: 0,
    ordersTotalCount: 0,
    treatsAmount: 0,
    vatAmount: 0
  });

  const handleRequest = useCallback(store => {
    const defaultParams = `?workdate=${store.workdate}&storeId=${store.storeId}`;
    const fromToParams = `?from=${store.workdate}&to=${store.workdate}&storeId=${store.storeId}`;
    setBusy(true);
    Promise.all([
      fetchData(ENDPOINT.USERS + `/activity` + defaultParams),
      postData(ENDPOINT.REPORTS + `/orders/hourly` + fromToParams),
      postData(ENDPOINT.REPORTS + `/metrics` + fromToParams)
    ])
      .then(data => {
        setUserReports(data[0]);
        setHourlyData(data[1].hourlyOrderDistribution);
        setMetrics(data[2]);
        setBusy(false);
      })
      .catch(ex => {
        console.log(ex);
        setBusy(false);
      });
  }, []);

  useEffect(() => {
    if (stores.length > 0) {
      setStoreId(stores[0].storeId);
    }
  }, [stores]);

  const pollRef = useRef();
  useEffect(() => {
    clearInterval(pollRef.current);
    pollRef.current = null;
    pollRef.current = poll(() => {
      setCounter(c => c + 1);
    }, 60 * 5);
  }, []);

  useEffect(() => {
    const store = stores.find(st => st.storeId === storeId);
    if (store && counter) {
      handleRequest(store);
    }
  }, [counter, handleRequest, storeId, stores]);

  return (
    <Fragment>
      <Grid item xs={12} style={{ visibility: busy ? "visible" : "hidden" }}>
        <LinearProgress color={"secondary"} />
      </Grid>
      <Box style={{ padding: 8, paddingTop: 4 }}>
        <Paper style={{ padding: 8 }}>
          <Grid container alignItems="center" spacing={2}>
            {!stores[0] && (
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  {stores[0] ? "Stores" : "No Stores"}
                </Typography>
              </Grid>
            )}
            {stores[0] &&
              stores.map(store => {
                return (
                  <Grid item key={store.storeId}>
                    <Fab
                      style={{
                        backgroundColor:
                          storeId === store.storeId ? "#3A415B" : "#fff",
                        color: storeId === store.storeId ? "#fff" : "#000"
                      }}
                      variant="extended"
                      onClick={() => setStoreId(store.storeId)}
                      className={classes.fab}
                    >
                      {store.storeName}
                    </Fab>
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <Statistics data={hourlyData} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <Grid container>
              <Metrics metrics={metrics} />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <Paper>
              <Waiters
                userReports={userReports}
                setUser={setUser}
                setOpen={setOpenOverview}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <UserOverview
        open={openOverview}
        handleClose={() => setOpenOverview(false)}
        user={user}
      />
    </Fragment>
  );
}

export default Dashboard;
