import React from 'react'
import { Paper, makeStyles } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(({
  root: {
    backgroundColor: props => props.color + "!important",
    color: props => (props.textColor || "#fff") + "!important",
    flexGrow: 1,
    margin: "4px",
    padding: "13px 16px",
    borderRadius: "10px",
  },
  label: {
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed!Important",
    opacity: .7,
    fontSize: "11px",
    letterSpacing: "1px",
    marginBottom: "10px"
  },
  value: {
    fontWeight: "bold",
    fontSize: "18px",
    fontFamily: "Roboto Mono"
  },
  customWidth: {
    fontSize: 14,
  },
}));

const InfoPanel = (props) => {

  const classes = useStyles(props);

  return (
    <Paper className={classes.root}>
      <div className={classes.label}>{props.label}</div>
      <div className={classes.value}>{props.value}</div>
    </Paper>
  )

}


const MetricContent = React.forwardRef(function MyComponent(props, ref) {
  const {color, label, value} = props
  //  Spread the props to the underlying DOM element.
  return <div {...props} ref={ref} style={{flex: 1}}>
    <InfoPanel color={color} label={label} value={value}/>
  </div>
});

const TooltipPanel = props => {
  const classes = useStyles()
  const {color, label, value, tooltip} = props
  return (
      <Tooltip title={tooltip} placement="top" classes={{ tooltip: classes.customWidth }}>
        <MetricContent color={color} label={label} value={value}/>
      </Tooltip>
  )
}

export {TooltipPanel, InfoPanel}
