import React, { Component } from 'react'
import { Redirect } from 'react-router'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Paper } from '@material-ui/core';
import { requestAccess } from '../../utils'
import { BASE_URL, HOME_PAGE } from '../../config';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import BusyIndicator from '../lib/BusyIndicator'
const md5 = require('md5');

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));


function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            {...other}
        />
    );
}


export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            email: '',
            selectedInput: 'email',
            showError: false,
            busy: false
        }

        this.handleTyping = this.handleTyping.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleEmail = this.handleEmail.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleShowError = this.handleShowError.bind(this)
    }

    handleLogin(username, password) {
        const hash = md5(password)
        const credentials = `${username}:${hash}`
        this.setState({ busy: true })
        requestAccess(BASE_URL + '/login', [], credentials)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw new Error('Something went wrong. Status: ' + res.status);
            }).then(credentials => {
                localStorage.setItem(`pos_credentials${HOME_PAGE}`, JSON.stringify(credentials));
                window.location.reload()
                this.setState({ busy: false })
            }).catch(err => {
                this.handleShowError()
                this.setState({ busy: false })
            })
    }

    handleShowError() {
        this.setState({ showError: true }, () => {
            setTimeout(() => {
                this.setState({ showError: false })
            }, 3000)
        })
    }

    handleSelection(selectedInput) {
        this.setState({ selectedInput })
    }

    handleTyping = (value) => {
        return this.state.selectedInput === 'email'
            ? this.setState({ email: value })
            : this.setState({ password: value })
    }

    handlePassword(password) {
        this.setState({ password })
    }

    handleEmail(email) {
        this.setState({ email })
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal })
    }


    render() {

        const {
            password,
            email,
            showError,
            busy
        } = this.state


        const getPasswordLogin = () => {
            return <Grid item container justify="center" alignItems="center" style={{ maxWidth: 480 }} id="loginContainer">
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: "center", flex: '1', padding: 16 }}>
                    <div style={{ display: 'flex' }}>
                        <img alt="logo" src={require('../../logo.png')} width="40" height="40" style={{ margin: 16 }} />
                        <h2 color="textSecondary" style={{ margin: 'auto' }}>Login</h2>
                    </div>

                    <Grid item container direction="column">
                        <TextField
                            onClick={() => this.handleSelection('email')}
                            id="filled-email-input"
                            style={{ flex: 1, margin: 8 }}
                            value={email}
                            onChange={(e) => this.handleEmail(e.target.value)}
                            label="Username"
                            type="text"
                            name="text"
                            autoComplete="email"
                            margin="normal"
                            variant="filled"
                        />
                        <TextField
                            style={{ flex: 1, margin: 8 }}
                            onClick={() => this.handleSelection('password')}
                            // InputProps={{ readOnly: true }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.handleLogin(email, password)
                                }
                            }}
                            id="filled-password-input"
                            value={password}
                            onChange={(e) => this.handlePassword(e.target.value)}
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            variant="filled"
                        />
                    </Grid>
                    <Grid item container justify="flex-end">
                        <Button onClick={() => this.handleLogin(email, password)}
                            size="large"
                            disabled={busy || !password || !email}
                            variant="contained"
                            style={{ marginTop: 16 }}
                            color="primary">
                            Login
                    </Button>
                    </Grid>
                </Paper>
            </Grid>
        }

        const mainContent = () => {
            return (
                <Grid id="loginContainer" container justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    {getPasswordLogin()}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={showError}
                    // autoHideDuration={5000}
                    >
                        <MySnackbarContentWrapper
                            variant="error"
                            message="Failed to login. Please try again."
                        />
                    </Snackbar>
                </Grid>
            );
        }
        return (
            !this.props.isAuth
                ? (<React.Fragment>
                    <BusyIndicator busy={busy} />
                    {mainContent()}
                </React.Fragment>)
                : (<Redirect to="/" />)
        );
    }
}
