import { HOME_PAGE } from "./config";

const getDefaultHeaders = () => {
    return {
        "Content-Type": "application/json",
        'Authorization': getJwtToken()
    }
}

export const buildSnack = (message, variant) => ({
    message, options: {
        variant,
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
        key: new Date().getTime() + Math.random(),
    }
})

export const getJwtToken = () => {
    const credentials = localStorage.getItem(`pos_credentials${HOME_PAGE}`);
    if (!credentials) return null
    const parseData = JSON.parse(credentials)
    return parseData ? parseData.token : null
}

// export const addSnack = (message, variant) => enqueueSnackbar(buildSnack(message, variant));

export const postData = (url, data = {}, readJson) => post(url, data, 'POST', readJson)

export const updateData = (url, data = {}, readJson) => post(url, data, 'PUT', readJson)

export const deleteData = (url, data = {}, readJson = false) => post(url, data, 'DELETE', readJson)

export const fetchData = (url, readJson = true) => {
    return fetch(url, { headers: getDefaultHeaders() })
        .then(response => {
            if (response.ok) return readJson ? response.json() : response
            throw Error(response.statusText)
        })
}

export const post = (url, data, method = 'POST', readJson = true) => {

    const options = {
        method: method,
        mode: "cors",
        body: JSON.stringify(data),
        headers: getDefaultHeaders(),
    };
    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                return readJson ? response.json() : response;
            }
            throw Error(response.statusText);
        })
}

export const requestAccess = (url, data, credentials) => {

    const options = {
        method: 'POST',
        mode: "cors",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            'Authorization': credentials
        },
    };
    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                return response
            }
            throw new Error('Invalid credentials')
        })
        .catch((message) => console.log(message))
}

export function poll(callBack, seconds) {
    callBack()
    return window.setInterval(callBack, seconds * 1000)
}
export const unwrap = (value, prop) => (value && value[prop]) || ""

export function toCurrency(amount) {
    // NOTICE: pass the formater inside toCurrency so it updates the currency symbol when it changed on the setup options without refresh
    return Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: getSetupOption("store.mode.currency") ? getSetupOption("store.mode.currency").optionValue : 'USD',
    }).format(amount)
}

export const getSetupOption = (option) => {
    const options = localStorage.getItem(`pos_setup_options${HOME_PAGE}`);
    if (!options) return null
    const parseData = JSON.parse(options)
    return parseData ? parseData.find(x => x.optionKey === option) : null
}

export const boolToYesNo = (value) => value ? 'YES' : 'NO';

export function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}
export const getCredentials = (prop) => {
    const credentials = localStorage.getItem(`pos_credentials${HOME_PAGE}`);
    if (!credentials) return null
    const parseData = JSON.parse(credentials)
    return parseData ? prop ? parseData[prop] : parseData : null
}

export const toRoundPrice = (priceValue) => {

    Number.prototype.prefix = function (precision = 1) {

        var units = ' K M G T P E Z Y'.split(' ');

        if (this < 0) {
            return '-' + Math.abs(this).prefix(precision);
        }

        if (this < 1) {
            return this + units[0];
        }

        var power = Math.min(
            Math.floor(Math.log(this) / Math.log(1024)),
            units.length - 1
        );

        return (this / Math.pow(1024, power)).toFixed(precision) + units[power];
    }


    return priceValue.prefix()
}

export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

export function percFormatter(value) {
    return value + "%"
}
export function currencyFormatter(value) {
    return toCurrency(value)
}
export function hourFormatter(value) {
    return value > 60 ? (value / 60).toFixed(1) + "h" : value + "min"
}

export function getSymbol(params) {
    return params && params.includes('?') ? "&" : "?"
}
export function toTime(date) {
    return (
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
    );
}
export function timeConvert(n) {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let str = ""
    if(hours) str += rhours + "h and "
    if (minutes) str +=  rminutes + "min.";
    return str
}
export const hasAccess = (permissionName) => {
    return getCredentials() && getCredentials().user.rolePermissions[permissionName]
}
