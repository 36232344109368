import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { makeStyles, Switch, Grid } from '@material-ui/core';

import ReportTable from './ReportTable';

require('chartjs-plugin-zoom')
require('hammerjs')

const colors = ["#7C359D", "#3F3771", "#F4D63A", "#1E8999", "#ec407a", "#33B378", "#384354", "#ff7961", "#6f79a8", "#26c6da"]

const useStyles = makeStyles(({
    paper: {
        padding: "8px",
        flexGrow: 1,
        backgroundColor: '#F7F7F8',
        // maxHeight: 250,
        // overflow: 'auto'
        height: 250,
        maxHeight: 250,
        minHeight: 250
    },
    title: {
        padding: "16px",
        color: '#181D30',
        fontSize: "12px",
        opacity: .5,
        textTransform: "uppercase",
        letterSpacing: "1px",
        borderBottom: "1px solid #E5E6E8"
    },
}));

const BarChart = ({ data, labels, title, formatter }) => {

    let pallet = []
    while (pallet.length < data.length) {
        pallet = [
            ...pallet,
            ...colors
        ]
    }

    const classes = useStyles();
    // #7C359D", "#3F3771", "#F4D63A"
    const chartData = {
        labels,
        datasets: [{
            type: 'bar',
            label: title,
            backgroundColor: pallet,
            borderColor: pallet,
            borderWidth: 2,
            hoverBackgroundColor: "#fff",
            hoverBorderColor: pallet,
            data
        }]
    }
    const options = {
        pan: {
            enabled: true,
            mode: 'x'
        },
        zoom: {
            enabled: true,
            mode: 'x'
        },
        legend: {
            display: false,
            variant: "bullet-primary"
        },
        plugins: {
            datalabels: {
                display: true,
                anchor: "end",
                align: "end",
                fontSize: 14,
                color: '#000',
                formatter: function (value, context) {
                    return formatter ? formatter(value) : value;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    // var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    // var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return formatter ? formatter(currentValue) : currentValue;

                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        scales: {
            yAxes: [{
                display: true,
                position: 'left',
                ticks: {
                    min: 0,
                    beginAtZero: true
                },
                // gridLines: {
                //     color: "rgba(0, 0, 0, 0)",
                // }
            }],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                    offsetGridLines: true
                },
                barThickness: 20,  // number (pixels) or 'flex'
                maxBarThickness: 20 // number (pixels)
            }]
        },
        maintainAspectRatio: false,

    }

    return (
        <div className={[classes.paper, "stats-chart"].join(" ")} >
            {/* <div className={classes.title}>Orders per hour</div> */}
            <Bar
                responsive={true}
                data={chartData}
                options={options}
                height={200}
            />
        </div>
    );

}


const useStyles2 = makeStyles(({
    title: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        display: "flex",
        lineHeight: 2,
        alignItems: "center",
        color: '#384354'
    },
    description: {
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: 2,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: '#181D30'
    }
}));

export default function TableBar(props) {
    const classes = useStyles2()

    const { labels, data, title, description, tableHead, tableBody, formatter } = props
    const [showChart, setShowChart] = useState(true)

    const handleChange = () => {
        setShowChart(!showChart);
    };


    return (
        <div style={{ overflow: 'auto', width: 100 + '%' }}>
            <Grid container alignItems="center" justify="space-between" style={{ marginBottom: 16 }}>
                <Grid item>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                </Grid>
                {tableBody && <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" style={{ opacity: showChart ? 0.4 : 1 }} src={require('../../assets/icons/ico-grid.svg')} />
                    <Switch
                        checked={showChart}
                        onChange={handleChange}
                        value="showChart"
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <img alt="" style={{ opacity: showChart ? 1 : 0.4 }} src={require('../../assets/icons/ico-pie.svg')} />
                </Grid>}
            </Grid>

            {
                showChart ?
                    <BarChart
                        title={title}
                        data={data}
                        formatter={formatter}
                        labels={labels}
                    />
                    : <ReportTable
                        tableBody={tableBody}
                        tableHead={tableHead}
                    />
            }

        </div>
    )
}
