import React from 'react'
import { Icon, Button, Typography, Grid } from '@material-ui/core'
// import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { postData } from '../../utils'
import { BASE_URL, HOME_PAGE } from '../../config'

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    colorPrimary: {
        margin: '0 8px',
        backgroundColor: 'red !important'
    },
    icon: {
        color: '#2c3249',
        textDecoration: "none",
        // flex: 1,
        display: 'flex'
    }
}));
const AppBarNav = (props) => {

    const classes = useStyles();


    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function logOut() {
        postData(BASE_URL + '/auth/logout')
            .then(() => {
                localStorage.removeItem(`pos_credentials${HOME_PAGE}`)
                window.location.reload()
            }).catch(ex => console.log(ex))
    }


    return (
        <div className="appBar show-sm">
            <Grid container alignItems="center" spacing={1} style={{ padding: "8px 16px" }}>
                <Grid item>
                    <img alt="" src={require('../../logo.png')} width={24} height={24} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Snapserve</Typography>
                </Grid>
            </Grid>
            <span className={classes.icon}>
                <Button onClick={handleClick} className={classes.icon}>
                    <Icon>more_vert</Icon>
                </Button>
            </span>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: 200, }, }}
            >
                <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
        </div>
    )
}


export default AppBarNav