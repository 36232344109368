import React, {useEffect, useState} from "react";
import {
  Button,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Switch,
  Typography
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {fetchData, postData, timeConvert, toCurrency} from "../../utils";
import {BASE_URL, ENDPOINT} from "../../config";
import SelectableOptions from "../lib/SelectableOptions";
import Kpis from "./Kpis";
import SectionTabs from "./SectionTabs";
import Overview from "./Overview";
import Revenue from "./Revenue";
import Payments from "./Payments";
import UserReports from "./UserReports";
import OrderHistory from "./OrderHistory";
import OrderTypes from "./OrderTypes";

const useStyles = makeStyles({
  paper: {
    padding: 16
  },
  fab: {
    backgroundColor: "white"
  },
  button: {
    fontSize: 18
  },
  search: {
    padding: 8
  },
  chartContainer: {
    // height: 'auto',
    // width: "100%"
    // flex: 1
  },
  select: {
    minWidth: 200
  },
  gridIdem: {
    // display: "flex"
  },
  flexItem: {
    flex: 1
  },
  floatButton: {
    color: "#000",
    position: "fixed",
    right: 0,
    bottom: 0,
    margin: 16
  },
  title: {
    padding: "0 8px",
    color: "#181D30",
    fontSize: "12px",
    opacity: 0.5,
    textTransform: "uppercase",
    letterSpacing: "1px",
    lineHeight: "26px"
  }
});

const dateRangesDefault = [
  {
    label: "Today",
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  {
    label: "Yesterday",
    from: moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD")
  },
  {
    label: "Week to Date",
    from: moment()
      .startOf("week")
      .format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  {
    label: "Month Date",
    from: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  {
    label: "Last 30 Days",
    from: moment()
      .add(-30, "days")
      .format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  {
    label: "Year to Date",
    from: moment()
      .startOf("year")
      .format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  },
  {
    label: "Last Year",
    from: moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD")
  },
  {
    label: "All",
    from: null,
    to: null
  },
  {
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  }
];

// "ordersTotalCount":53,
// "ordersCancelCount":0,
// "incomeTotalAmount":526.8099999999998,
// "netAmount":776.1100000000002,
// "vatAmount":47.08,
// "discountAmount":8.68,
// "treatsAmount":0.0,
// "cashAmount":526.8099999999998,
// "creditAmount":0.0
const metricsPropertyLabels = [
  { label: "Total Sales", id: "incomeTotalAmount" },
  { label: "Net", id: "netAmount" },
  { label: "Tax", id: "vatAmount" },
  { label: "Discount", id: "discountAmount" },
  { label: "Treat", id: "treatsAmount" }
];

// cateringOrderCount: 0
// deliveryOrderCount: 0
// tablesOrderCount: 51
// takeawayOrderCount: 2
// totalOrderCount: 53
const orderTypesPropertyLabels = [
  { label: "Catering", id: "cateringOrderCount" },
  { label: "Delivery", id: "deliveryOrderCount" },
  { label: "Dine in", id: "tablesOrderCount" },
  { label: "Take out", id: "takeawayOrderCount" }
];

const dateOptions = [
  { id: 0, name: dateRangesDefault[0].label },
  { id: 1, name: dateRangesDefault[1].label },
  { id: 2, name: dateRangesDefault[2].label },
  { id: 3, name: dateRangesDefault[3].label },
  { id: 4, name: dateRangesDefault[4].label },
  { id: 5, name: dateRangesDefault[5].label },
  { id: 6, name: dateRangesDefault[6].label },
  { id: 7, name: dateRangesDefault[7].label },
  { id: 8, name: "Custom" }
];

function getSymbol(params) {
  return params && params.includes("?") ? "&" : "?";
}

function handlePerc(array, index, prop) {
  const total = array.reduce((acc, cv) => acc + cv[prop], 0);
  if (index === array.length - 1) {
    const remainingPercentage = array.slice(0, index).reduce((acc, value) => {
      return acc + Math.round((value[prop] / total) * 100);
    }, 0);

    return 100 - remainingPercentage;
  }
  return Math.round((array[index][prop] / total) * 100);
}

const safeArray = array => {
  return array ? array : [];
};

const safeDivision = (num, divider) => {
  return divider ? num / divider : 0;
};

function DateSelector(props) {
  const classes = useStyles();
  const {
    dateRanges,
    dateRangeIndex,
    setDateRangeIndex,
    setDateRanges
  } = props;
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [form, setForm] = useState({
    from: "",
    to: ""
  });

  useEffect(() => {
    const form = dateRanges.find(x => !x.label);
    setForm(form);
  }, [dateRanges]);

  const handleDateChange = prop => value => {
    setForm(prevForm => ({
      ...prevForm,
      [prop]: moment(value).format("YYYY-MM-DD")
    }));
  };

  const handleShow = () => {
    const newDateRanges = [...dateRanges.filter(x => x.label), form];
    setDateRanges(newDateRanges);
    const index = newDateRanges.length - 1;
    setDateRangeIndex(index);
    setForm(dateRanges[index]);
  };

  return (
    <Grid container direction="column">
      <Grid container justify="center" alignItems="center" spacing={2}>
        {showAdvanced ? (
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <Grid item>
              <div style={{ fontSize: 12 }}>FROM</div>
            </Grid>
            <Grid item>
              <Paper
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: "white"
                }}
              >
                <KeyboardDatePicker
                  className={classes.search}
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="date-picker-from"
                  value={form.from}
                  onChange={handleDateChange("from")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Paper>
            </Grid>
            <Grid item>
              <div style={{ fontSize: 12 }}>TO</div>
            </Grid>
            <Grid item>
              <Paper
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: "white"
                }}
              >
                <KeyboardDatePicker
                  className={classes.search}
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="date-picker-to"
                  value={form.to}
                  onChange={handleDateChange("to")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Paper>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleShow}
                size="large"
                className={classes.button}
              >
                SHOW
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        ) : (
          dateRanges
            .filter(x => x.label)
            .map((dr, index) => {
              return (
                <Grid item key={index}>
                  <Fab
                    style={{
                      backgroundColor:
                        dateRangeIndex === index ? "#F4D63A" : "#fff"
                    }}
                    variant="extended"
                    onClick={() => setDateRangeIndex(index)}
                    className={classes.fab}
                  >
                    {dr.label}
                  </Fab>
                </Grid>
              );
            })
        )}
        <Grid item container justify="flex-end" className={classes.flexItem}>
          <FormControlLabel
            control={
              <Switch
                checked={showAdvanced}
                onChange={() => setShowAdvanced(pr => !pr)}
                value={showAdvanced}
                color="primary"
              />
            }
            label="Advanced"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function ResponsiveAdvancedPicker(props) {
  const classes = useStyles();

  const { dateRanges, setDateRangeIndex, setDateRanges, showAdvanced } = props;

  const [form, setForm] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    const form = dateRanges.find(x => !x.label);
    setForm(form);
  }, [dateRanges]);

  const handleDateChange = prop => value => {
    setForm(prevForm => ({
      ...prevForm,
      [prop]: moment(value).format("YYYY-MM-DD")
    }));
  };

  const handleShow = () => {
    const newDateRanges = [...dateRanges.filter(x => x.label), form];
    setDateRanges(newDateRanges);
    const index = newDateRanges.length - 1;
    setDateRangeIndex(index);
    setForm(dateRanges[index]);
  };

  return (
    showAdvanced && (
      <Paper
        className={[classes.paper, "show-sm"].join(" ")}
        style={{ marginTop: 8 }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item container justify="center">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  className={[classes.search, "w-100"].join(" ")}
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="date-picker-from"
                  value={form.from}
                  onChange={handleDateChange("from")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  className={[classes.search, "w-100"].join(" ")}
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="date-picker-to"
                  value={form.to}
                  onChange={handleDateChange("to")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShow}
                  size="large"
                  className={[classes.button, "w-100"].join(" ")}
                >
                  SHOW
                </Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Paper>
    )
  );
}

export default function Reports(props) {
  const classes = useStyles();
  const { stores } = props;
  const [dateRanges, setDateRanges] = useState(dateRangesDefault);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [busy, setBusy] = useState([]);
  const [dateRangeIndex, setDateRangeIndex] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const [paymentsPerMethod, setPaymentsPerMethod] = useState({
    details: [],
    paymentMethodDistribution: [],
    label: "",
    totalPaymentCount: 0,
    totalPaymentAmount: 0
  });
  const [paymentsPerTerminal, setPaymentsPerTerminal] = useState({
    details: [],
    paymentsPerTerminalDistribution: [],
    label: "",
    totalPaymentCount: 0,
    totalPaymentAmount: 0
  });
  const [paymentsPerUser, setPaymentsPerUser] = useState({
    details: [],
    paymentsPerUserDistribution: [],
    label: "",
    totalPaymentCount: 0,
    totalPaymentAmount: 0
  });
  const [workingHours, setWorkingHours] = useState({
    workingMinutes: [],
    details: []
  });
  const [ordersHourly, setOrdersHourly] = useState({
    label: "",
    hourlyOrderDistribution: [],
    details: []
  });
  const [revenuePerDate, setRevenuePerDate] = useState({ details: [] });
  const [revenuePerTable, setRevenuePerTable] = useState({
    details: [],
    revenuePerTableDistribution: []
  });
  const [revenuePerCategoryGroup, setRevenuePerCategoryGroup] = useState({
    details: [],
    revenuePerCategoryGroupDistribution: []
  });
  const [revenuePerCategory, setRevenuePerCategory] = useState({
    details: [],
    revenuePerCategoryDistribution: []
  });
  const [topItems, setTopItems] = useState([]);
  const [kpis, setKpis] = useState({});
  const [ordersData, setOrdersData] = useState({
    content: [],
    totalPages: 0,
    totalELements: 0,
    size: 0,
    numberOfElements: 1
  });
  const [currentOrderPage, setCurrentOrderPage] = useState(0);
  const [paymentsPerOrderType, setPaymentsPerOrderType] = useState({
    deliveryOrderCount: 0,
    tablesOrderCount: 0,
    takeawayOrderCount: 0,
    totalOrderCount: 0,
    cateringOrderCount: 0,
    details: []
  });
  const [metrics, setMetrics] = useState({
    cashAmount: 0,
    creditAmount: 0,
    discountAmount: 0,
    incomeTotalAmount: 0,
    netAmount: 0,
    ordersCancelCount: 0,
    ordersTotalCount: 0,
    treatsAmount: 0,
    vatAmount: 0,
    details: []
  });

  const handleRequest = React.useCallback(
    (endpoint, handle, extraParams, method = "POST") => {
      let params = "";
      const range = dateRanges[dateRangeIndex];
      if (range.from) {
        params += `?from=${range.from}&to=${range.to}`;
        if (extraParams) params += "&" + extraParams;
      } else {
        if (extraParams) params += "?" + extraParams;
      }
      if (storeId) {
        params += getSymbol(params) + "storeId=" + storeId;
      }
      setBusy(prevBusy => [...prevBusy, { endpoint, busy: true }]);

      if (method === "GET") {
        fetchData(endpoint + params)
          .then(data => {
            handle(data);
            setBusy(prevBusy =>
              prevBusy.map(e => {
                return e.endpoint === endpoint ? { ...e, busy: false } : e;
              })
            );
          })
          .catch(ex => {
            setBusy(prevBusy =>
              prevBusy.map(e => {
                return e.endpoint === endpoint ? { ...e, busy: false } : e;
              })
            );
          });
      } else {
        postData(ENDPOINT.REPORTS + endpoint + params)
          .then(data => {
            handle(data);
            setBusy(prevBusy =>
              prevBusy.map(e => {
                return e.endpoint === endpoint ? { ...e, busy: false } : e;
              })
            );
          })
          .catch(ex => {
            setBusy(prevBusy =>
              prevBusy.map(e => {
                return e.endpoint === endpoint ? { ...e, busy: false } : e;
              })
            );
          });
      }
    },
    [dateRangeIndex, dateRanges, storeId]
  );

  const handleChangeDate = React.useCallback(index => {
    setDateRangeIndex(index);
    setShowAdvanced(index === 8);
  }, []);

  const handleChangeStore = React.useCallback(id => {
    setStoreId(id);
  }, []);

  useEffect(() => {
    switch (tabIndex) {
      case 0: {
        handleRequest("/metrics", setMetrics);
        handleRequest("/top_items", setTopItems, "limit=10");
        handleRequest("/orders/hourly", setOrdersHourly);
        break;
      }
      case 1: {
        handleRequest("/kpis", setKpis);
        break;
      }
      case 2: {
        handleRequest("/revenue_per_table", setRevenuePerTable);
        handleRequest("/revenue_per_date", setRevenuePerDate);
        handleRequest(
          "/revenue_per_category_group",
          setRevenuePerCategoryGroup
        );
        handleRequest("/revenue_per_category", setRevenuePerCategory);
        break;
      }
      case 3: {
        handleRequest("/payment_methods", setPaymentsPerMethod);
        handleRequest("/payments_per_terminal", setPaymentsPerTerminal);
        handleRequest("/payments_per_user", setPaymentsPerUser);
        break;
      }
      case 4: {
        handleRequest("/users/working_minutes", setWorkingHours);
        break;
      }
      case 5: {
        handleRequest("/orders/type", setPaymentsPerOrderType);
        break;
      }
      case 6: {
        setCurrentOrderPage(0);
        // handleRequest("/orders/type", setPaymentsPerOrderType);
        break;
      }
      default:
        break;
    }
  }, [handleRequest, tabIndex]);

  useEffect(() => {
    if (tabIndex !== 6) return;
    handleRequest(
      BASE_URL + "/orders",
      setOrdersData,
      "page=" + currentOrderPage,
      "GET"
    );
  }, [handleRequest, currentOrderPage, tabIndex]);

  const usePaymentMethods = React.useMemo(() => {
    return {
      tableHead: ["#", "Method name", "Total payments", "Gross"],
      tableBody: paymentsPerMethod.paymentMethodDistribution
        .sort((a, b) => b.paymentAmount - a.paymentAmount)
        .map((method, index) => {
          return {
            id: index + 1,
            name: method.paymentMethodName,
            count: method.paymentCount,
            amount: toCurrency(method.paymentAmount)
          };
        }),
      data: paymentsPerMethod.paymentMethodDistribution.map((x, i) =>
        handlePerc(
          paymentsPerMethod.paymentMethodDistribution,
          i,
          "paymentAmount"
        )
      ),
      labels: paymentsPerMethod.paymentMethodDistribution.map(
        x => x.paymentMethodName
      )
    };
  }, [paymentsPerMethod.paymentMethodDistribution]);

  const useTerminals = React.useMemo(() => {
    return {
      tableHead: ["#", "Terminal name", "Total payments", "Gross"],
      tableBody: paymentsPerTerminal.paymentsPerTerminalDistribution
        .sort((a, b) => b.paymentAmount - a.paymentAmount)
        .map((method, index) => {
          return {
            id: index + 1,
            name: method.terminalName,
            count: method.paymentCount,
            amount: toCurrency(method.paymentAmount)
          };
        }),
      data: paymentsPerTerminal.paymentsPerTerminalDistribution.map((x, i) =>
        handlePerc(
          paymentsPerTerminal.paymentsPerTerminalDistribution,
          i,
          "paymentAmount"
        )
      ),
      labels: paymentsPerTerminal.paymentsPerTerminalDistribution.map(
        x => x.terminalName
      )
    };
  }, [paymentsPerTerminal.paymentsPerTerminalDistribution]);

  const useUsers = React.useMemo(() => {
    return {
      tableHead: ["#", "Method name", "Total payments", "Gross"],
      tableBody: paymentsPerUser.paymentsPerUserDistribution
        .sort((a, b) => b.paymentAmount - a.paymentAmount)
        .map((method, index) => {
          return {
            id: index + 1,
            name: method.userName,
            count: method.paymentCount,
            amount: toCurrency(method.paymentAmount)
          };
        }),
      data: paymentsPerUser.paymentsPerUserDistribution.map((x, i) =>
        handlePerc(
          paymentsPerUser.paymentsPerUserDistribution,
          i,
          "paymentAmount"
        )
      ),
      labels: paymentsPerUser.paymentsPerUserDistribution.map(x => x.userName)
    };
  }, [paymentsPerUser.paymentsPerUserDistribution]);

  const useOrderTypes = React.useMemo(() => {
    const data = [
      { type: "Dine in", count: paymentsPerOrderType.tablesOrderCount },
      { type: "Take out", count: paymentsPerOrderType.takeawayOrderCount },
      { type: "Delivery", count: paymentsPerOrderType.deliveryOrderCount },
      { type: "Catering", count: paymentsPerOrderType.cateringOrderCount }
    ];
    return {
      tableHead: ["Order type", "Total payments"],
      tableBody: data.sort((a, b) => b.count - a.count),
      data: data.map((x, i) => handlePerc(data, i, "count")),
      labels: data.map(x => x.type)
    };
  }, [paymentsPerOrderType]);

  const useTopItems = React.useMemo(() => {
    return {
      tableHead: ["#", "Item name", "Discount", "Net", "Vat", "Gross"],
      tableBody: topItems
        .sort((a, b) => b.amountTotal - a.amountTotal)
        .map((item, index) => {
          return {
            id: index + 1,
            name: item.itemName,
            discount: toCurrency(item.amountDiscount),
            net: toCurrency(item.amountNet),
            vat: toCurrency(item.amountVat),
            revenue: toCurrency(item.amountTotal)
          };
        }),
      data: topItems.map((x, i) => handlePerc(topItems, i, "amountTotal")),
      labels: topItems.map(x => x.itemName)
    };
  }, [topItems]);

  const useCategoryGroups = React.useMemo(() => {
    return {
      tableHead: ["#", "Category name", "Discount", "Net", "Vat", "Gross"],
      tableBody: revenuePerCategoryGroup.revenuePerCategoryGroupDistribution
        .sort((a, b) => b.amountTotal - a.amountTotal)
        .map((item, index) => {
          return {
            id: index + 1,
            name: item.categoryGroupName,
            discount: toCurrency(item.amountDiscount),
            net: toCurrency(item.amountNet),
            vat: toCurrency(item.amountVat),
            revenue: toCurrency(item.amountTotal)
          };
        }),
      // data: revenuePerCategoryGroup.data.map((x, i) => handlePerc(revenuePerCategoryGroup.data, i, "amountTotal")),
      data: revenuePerCategoryGroup.revenuePerCategoryGroupDistribution.map(
        x => x.amountTotal
      ),
      labels: revenuePerCategoryGroup.revenuePerCategoryGroupDistribution.map(
        x => x.categoryGroupName
      )
    };
  }, [revenuePerCategoryGroup.revenuePerCategoryGroupDistribution]);

  const useTables = React.useMemo(() => {
    return {
      tableHead: ["#", "Table code", "Discount", "Net", "Vat", "Gross"],
      tableBody: revenuePerTable.revenuePerTableDistribution
        .sort((a, b) => b.amountTotal - a.amountTotal)
        .map((item, index) => {
          return {
            id: index + 1,
            name: item.tableCode,
            discount: toCurrency(item.amountDiscount),
            net: toCurrency(item.amountNet),
            vat: toCurrency(item.amountVat),
            revenue: toCurrency(item.amountTotal)
          };
        }),
      // data: revenuePerTable.data.map((x, i) => handlePerc(revenuePerTable.data, i, "amountTotal")),
      data: revenuePerTable.revenuePerTableDistribution.map(x => x.amountTotal),
      labels: revenuePerTable.revenuePerTableDistribution.map(x => x.tableCode)
    };
  }, [revenuePerTable.revenuePerTableDistribution]);

  const useHourly = React.useMemo(() => {
    return {
      tableHead: ["#", "Hour", "Orders Count"],
      tableBody: ordersHourly.hourlyOrderDistribution.map((item, index) => {
        return {
          id: index + 1,
          hour: item.label,
          count: item.value
        };
      }),
      data: ordersHourly.hourlyOrderDistribution.map(x => x.value),
      labels: ordersHourly.hourlyOrderDistribution.map(x => x.label)
    };
  }, [ordersHourly]);

  const useMetricsData = React.useMemo(() => {
    const myData = [];

    metricsPropertyLabels.forEach(set => {
      let setData = [];
      safeArray(metrics.details).forEach(setDetail => {
        setData.push(setDetail[set.id]);
      });
      myData.push({
        data: setData,
        label: set.label
      });
    });

    return {
      data: myData,
      labels: safeArray(metrics.details).map(x => x.label)
    };
  }, [metrics]);

  const useOrderTypesData = React.useMemo(() => {
    const myData = [];

    orderTypesPropertyLabels.forEach(set => {
      let setData = [];
      safeArray(paymentsPerOrderType.details).forEach(setDetail => {
        setData.push(setDetail[set.id]);
      });
      myData.push({
        data: setData,
        label: set.label
      });
    });

    return {
      data: myData,
      labels: safeArray(paymentsPerOrderType.details).map(x => x.label)
    };
  }, [paymentsPerOrderType]);

  const useRevenues = React.useMemo(() => {
    return {
      tableHead: ["Work Date", "Discount", "Net", "Vat", "Gross"],
      tableBody: revenuePerDate.details
        .sort((a, b) => b.amountTotal - a.amountTotal)
        .map(pay => {
          return {
            workdate: pay.workdate,
            amountDiscount: toCurrency(pay.amountDiscount),
            amountNet: toCurrency(pay.amountNet),
            amountVat: toCurrency(pay.amountVat),
            amountTotal: toCurrency(pay.amountTotal)
          };
        }),
      data: revenuePerDate.details.map(x => x.amountTotal),
      labels: revenuePerDate.details.map(x => x.label)
    };
  }, [revenuePerDate.details]);

  const useCategories = React.useMemo(() => {
    return {
      tableHead: ["#", "Category name", "Discount", "Net", "Vat", "Gross"],
      tableBody: revenuePerCategory.revenuePerCategoryDistribution
        .sort((a, b) => b.amountTotal - a.amountTotal)
        .map((item, index) => {
          return {
            id: index + 1,
            name: item.categoryName,
            discount: toCurrency(item.amountDiscount),
            net: toCurrency(item.amountNet),
            vat: toCurrency(item.amountVat),
            revenue: toCurrency(item.amountTotal)
          };
        }),
      // data: revenuePerCategory.data.map((x, i) => handlePerc(revenuePerCategory.data, i, "amountTotal")),
      data: revenuePerCategory.revenuePerCategoryDistribution.map(
        x => x.amountTotal
      ),
      labels: revenuePerCategory.revenuePerCategoryDistribution.map(
        x => x.categoryName
      )
    };
  }, [revenuePerCategory.revenuePerCategoryDistribution]);

  const useWorkingHoursData = React.useMemo(() => {
    return {
      tableHead: ["#", "User", "Total"],
      tableBody: safeArray(workingHours.workingMinutes)
        .sort((a, b) => b.minutes - a.minutes)
        .map((item, index) => {
          return {
            id: index,
            name: item.userName,
            hours: timeConvert(item.minutes)
          };
        }),
      // data: revenuePerTable.data.map((x, i) => handlePerc(revenuePerTable.data, i, "amountTotal")),
      data: safeArray(workingHours.workingMinutes).map(x => x.minutes / 60),
      labels: safeArray(workingHours.workingMinutes).map(x => x.userName)
    };
  }, [workingHours]);

  const useReceipts = React.useMemo(() => {
    return {
      tableHead: ["#", "Workdate", "Gross", "Net", "Count"],
      tableBody: safeArray(metrics.details).map((item, index) => {
        return {
          id: index + 1,
          workdate: item.label,
          gross: toCurrency(
            safeDivision(item.incomeTotalAmount, item.ordersTotalCount)
          ),
          net: toCurrency(safeDivision(item.netAmount, item.ordersTotalCount)),
          count: item.ordersTotalCount
        };
      }),
      data: safeArray(metrics.details).map(x =>
        safeDivision(x.incomeTotalAmount, x.ordersTotalCount)
      ),
      labels: safeArray(metrics.details).map(x => x.label)
    };
  }, [metrics]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Grid
        item
        xs={12}
        style={{ visibility: busy.find(x => x.busy) ? "visible" : "hidden" }}
      >
        <LinearProgress color={"secondary"}/>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          maxWidth: 100 + "%",
          margin: "auto",
          padding: "0 4px 4px 4px"
        }}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={2}>
              <Grid item container justify="center" className="show-sm">
                <SelectableOptions
                  className={classes.select}
                  controlId="range-index"
                  label="Select date"
                  hasNone={false}
                  // busy={busy}
                  value={dateOptions[dateRangeIndex].id}
                  values={dateOptions}
                  handleChange={e => handleChangeDate(parseInt(e.target.value))}
                />
              </Grid>
              <Grid item className="show-lg" container>
                <DateSelector
                  dateRanges={dateRanges}
                  dateRangeIndex={dateRangeIndex}
                  setDateRanges={setDateRanges}
                  setDateRangeIndex={setDateRangeIndex}
                />
              </Grid>
            </Grid>
          </Paper>
          <ResponsiveAdvancedPicker
            dateRanges={dateRanges}
            dateRangeIndex={dateRangeIndex}
            setDateRanges={setDateRanges}
            showAdvanced={showAdvanced}
            setDateRangeIndex={setDateRangeIndex}
          />
        </Grid>
        <Grid item xs={12} className={classes.flexContainer}>
          <Paper
            className={[
              classes.paper,
              classes.chartContainer,
              classes.flexItem
            ].join(" ")}
          >
            <Grid container justify="center" className="show-sm">
              <SelectableOptions
                className={classes.select}
                controlId="store-id"
                label="Select store"
                hasNone={true}
                // busy={busy}
                value={storeId}
                values={stores}
                handleChange={e => handleChangeStore(parseInt(e.target.value))}
              />
            </Grid>
            <Grid container alignItems="center" className="show-lg" spacing={2}>
              {!stores[0] && (
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {stores[0] ? "Stores" : "Loading stores.."}
                  </Typography>
                </Grid>
              )}
              {stores[0] &&
                [{ id: 0, name: "All stores" }, ...stores].map(store => {
                  return (
                    <Grid item key={store.id}>
                      <Fab
                        style={{
                          backgroundColor:
                            storeId === store.id ? "#3A415B" : "#fff",
                          color: storeId === store.id ? "#fff" : "#000"
                        }}
                        variant="extended"
                        onClick={() => setStoreId(store.id)}
                        className={classes.fab}
                      >
                        {store.name}
                      </Fab>
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </Grid>

        <SectionTabs
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          options={[
            {
              component: (
                <Overview
                  metrics={metrics}
                  useHourly={useHourly}
                  useTopItems={useTopItems}
                  useMetricsData={useMetricsData}
                />
              ),
              title: "Overview"
            },
            {
              component: <Kpis kpis={kpis} />,
              title: "Kpis"
            },
            {
              component: (
                <Revenue
                  useReceipts={useReceipts}
                  useRevenues={useRevenues}
                  useTables={useTables}
                  useCategories={useCategories}
                  useCategoryGroups={useCategoryGroups}
                />
              ),
              title: "Sales"
            },
            {
              component: (
                <Payments
                  useUsers={useUsers}
                  usePaymentMethods={usePaymentMethods}
                  useTerminals={useTerminals}
                />
              ),
              title: "Payments"
            },
            {
              component: (
                <UserReports useWorkingHoursData={useWorkingHoursData} />
              ),
              title: "Working hours"
            },

            {
              component: (
                <OrderTypes
                  useOrderTypes={useOrderTypes}
                  useOrderTypesData={useOrderTypesData}
                />
              ),
              title: "Order Types"
            },
            {
              component: (
                <OrderHistory
                  ordersData={ordersData}
                  currentPage={currentOrderPage}
                  setCurrentOrderPage={setCurrentOrderPage}
                  stores={stores}
                />
              ),
              title: "Orders History"
            }
          ]}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
