import React from 'react'
import { makeStyles, Table, Paper } from '@material-ui/core';
import TableSearch from './TableSearch';

const useStyles = makeStyles({
  root: {
    position: "relative",
    margin: "8px",
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  scrollable: {
    overflow: "auto",
    flexGrow: 1
  },
  table: {
    "& thead th": {
      textTransform: "uppercase"
    }
  }
})

export default props => {

  const classes = useStyles();

  return (
    <>
      <TableSearch
        hint={props.searchHint}
        onSearch={props.handleSearchQuery}
        query={props.searchQuery}
        handleCreate={props.handleCreate}
        addLabel={props.addLabel}
        onEnterAction={props.onEnterAction}
        filters={props.filters}
        filterChanged={props.filterChanged}
        selectedFilter={props.selectedFilter}
        filterLabel={props.filterLabel}
        hasAutoFocus={props.hasAutoFocus}
      />
      <Paper className={classes.root}>
        <div className={classes.scrollable}>
          <Table className={classes.table}>
            {props.children}
          </Table>
        </div>
      </Paper>
    </>
  )

}
