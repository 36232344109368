import React from 'react'
import { DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles(({
  delete: {
    position: "absolute",
    top: "8px",
    right: "8px"
  }
}));

export default props => {

  const classes = useStyles();
  const {
    entity,
    entityName,
    handleDelete,
    busy,
    titleOverride
  } = props

  return (
    <DialogTitle>
      {titleOverride ? titleOverride : (entity && entity.id ? "Edit " : "Create ") + entityName}
      {entity && entity.id && handleDelete && (
        <IconButton
          className={classes.delete}
          onClick={handleDelete}
          disabled={busy}
        >
          <Delete />
        </IconButton>
      )}
    </DialogTitle>
  )
}