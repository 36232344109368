import React from 'react'
import { FormControl, InputLabel, Select, FilledInput, MenuItem } from '@material-ui/core';

const SelectableOptions = ({
    controlId,
    label,
    busy,
    value,
    values,
    handleChange,
    displayProp = 'name',
    selectProp = 'id',
    hasNone = true
}) => (
        <FormControl
            variant="filled"
            className="w-100">
            <InputLabel shrink htmlFor={controlId}>
                {label}
            </InputLabel>
            <Select
                disabled={busy}
                value={value}
                onChange={handleChange}
                input={<FilledInput name={controlId} id={controlId} />}
                name={controlId + "-select"}
            >
                {hasNone && <MenuItem value={0}>
                    <em>All</em>
                </MenuItem>}
                {values.map(value => (
                    <MenuItem
                        key={`${controlId}-options-${value.id}`}
                        value={value[selectProp]}
                    >
                        {value[displayProp]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

export default SelectableOptions;
