import React from 'react'
import { Paper, TextField, Button, withStyles } from '@material-ui/core'
// import SelectableOptions from '../Manager/Common/SelectableOptions';

const styles = theme => ({
  root: {
    margin: "8px",
    padding: "45px",
    display: "flex",
  },
  button: {
    // fontSize: "18px",
    marginLeft: "40px"
  },
  search: {
    fontFamily: "Roboto",
    flexGrow: 1,
    borderRadius: "5px",
  }
});

const TableSearch = withStyles(styles)(({ classes, hint, query, onSearch, handleCreate, addLabel, type = 'search', onEnterAction, filters, filterChanged, selectedFilter, filterLabel, hasAutoFocus = false }) => {

  // const handleChange = useCallback(e => {
  //   filterChanged(filters.find(x => x.id === e.target.value));
  // }, [filters, filterChanged]);

  return <Paper className={classes.root}>
    {/* {filters && <Box mr={4}><SelectableOptions
      controlId="master-filter"
      label={filterLabel}
      value={(selectedFilter && selectedFilter.id) || 0}
      values={filters}
      handleChange={handleChange}
      hasNone={false}
    /></Box>} */}
    <TextField
      autoFocus={hasAutoFocus}
      onKeyPress={(e) => {
        if (e.key === 'Enter') onEnterAction && onEnterAction(query)
      }}
      className={classes.search}
      id="filled-input"
      value={query}
      onChange={(e) => onSearch(e.target.value)}
      label={hint}
      type={type}
      name="query"
      variant="filled"
    />
    {handleCreate &&
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleCreate}>
        {`+ ${addLabel}`}
      </Button>
    }
  </Paper>
});

export default TableSearch;
