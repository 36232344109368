import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import TableBar from './TableBar'
import { currencyFormatter } from '../../utils'

const useStyles = makeStyles(({
    paper: {
        padding: 16,
    }
}));

export default function Revenue({
    useReceipts,
    useRevenues,
    useTables,
    useCategoryGroups,
    useCategories,
}) {
    const classes = useStyles()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            formatter={currencyFormatter}
                            title="Sales per date"
                            data={useRevenues.data}
                            tableHead={useRevenues.tableHead}
                            tableBody={useRevenues.tableBody}
                            labels={useRevenues.labels}
                        />
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            formatter={currencyFormatter}
                            title="Average value per receipt"
                            data={useReceipts.data}
                            tableHead={useReceipts.tableHead}
                            tableBody={useReceipts.tableBody}
                            labels={useReceipts.labels}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            formatter={currencyFormatter}
                            title="Sales per table"
                            data={useTables.data}
                            tableHead={useTables.tableHead}
                            tableBody={useTables.tableBody}
                            labels={useTables.labels} />
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            formatter={currencyFormatter}
                            title="Sales per Catetory Group"
                            data={useCategoryGroups.data}
                            tableHead={useCategoryGroups.tableHead}
                            tableBody={useCategoryGroups.tableBody}
                            labels={useCategoryGroups.labels} />
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            formatter={currencyFormatter}
                            title="Sales per Catetory"
                            data={useCategories.data}
                            tableHead={useCategories.tableHead}
                            tableBody={useCategories.tableBody}
                            labels={useCategories.labels} />
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}
