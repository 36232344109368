import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 4,
        width: '100%',
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
    },
    tabIndicator: {
        backgroundColor: "#F4D63A",
        height: "4px"
    }
}));

export default function SectionTabs({ options, tabIndex, setTabIndex }) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };


    const renderTabs = useMemo(() => {
        const tabs = options.map((tab, index) => {
            return <Tab label={tab.title} {...a11yProps(index)} />
        })
        const panels = options.map((tab, index) => {
            return <TabPanel value={tabIndex} index={index}>
                {tab.component}
            </TabPanel>
        })
        return {
            tabs: tabs,
            panels: panels
        }

    }, [options, tabIndex])

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" style={{ borderRadius: "4px 4px 0 0" }}>
                <Tabs
                    classes={{
                        indicator: classes.tabIndicator
                    }}
                    value={tabIndex}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {renderTabs.tabs}
                </Tabs>
            </AppBar>
            {renderTabs.panels}
        </div>
    );
}
