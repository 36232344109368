import React, { useState } from 'react'
import { makeStyles, Slide, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import EditDialogHeader from './EditDialogHeader';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import BusyIndicator from './BusyIndicator';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  content: {
    position: "relative"
  },
  cancel: {
    // textTransform: 'none'
  },
  container: {
    display: "flex",
    flexDirection: "column"
  }
})

export default props => {
  const {
    onClose,
    onDeleted,
    busy,
    entity,
    entityName,
    createEntity,
    updateEntity,
    deleteEntity,
    children,
    ...other
  } = props

  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleCancel = () => onClose(false) // TODO: Ask for confirmation in case of changes
  const handleUpdate = updateEntity ? () => updateEntity(entity) : null
  const handleCreate = createEntity ? () => createEntity(entity) : null
  const handleDelete = deleteEntity ? () => setDeleteOpen(true) : null
  const handleSave = entity.id ? handleUpdate : handleCreate;
  const handleCloseDelete = (ok) => {
    setDeleteOpen(false);
    if (ok) {
      const { id } = entity;
      deleteEntity(id);
      onDeleted && onDeleted();
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={props.maxWidth || "sm"}
      onClose={handleCancel}
      {...other}
      disableBackdropClick={busy}
      disableEscapeKeyDown={busy}
      TransitionComponent={Transition}
    >
      <EditDialogHeader
        entity={entity}
        entityName={entityName}
        handleDelete={handleDelete}
        busy={busy}
        titleOverride={handleSave ? null : "View " + entityName}
      />
      <DialogContent dividers className={classes.content}>
        <BusyIndicator busy={busy} />

        <div className={classes.container}>
          {children}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancel}
          disabled={busy}
          onClick={handleCancel}
        >
          {handleSave ? 'Cancel' : 'Close'}
        </Button>
        {handleSave &&
          <Button
            disabled={busy}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Save
        </Button>
        }
      </DialogActions>
      {entity && entity.id && (
        <DeleteConfirmationDialog
          open={deleteOpen}
          onClose={handleCloseDelete}
          entityName={entityName}
        />
      )}

    </Dialog>)
}