import React from 'react'
import { Button, Icon } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
// import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    colorPrimary: {
        margin: '0 8px',
        backgroundColor: 'red !important'
    },
    icon: {
        color: '#2c3249',
        textDecoration: "none",
        flex: 1,
        display: 'flex'
    }
}));
const BottomNav = (props) => {

    const classes = useStyles();

    return (
        <div className="bottomNav show-sm">

            <NavLink activeClassName="selected" to={`${props.match.path}/dashboard`} className={classes.icon}>
                <Button className={classes.icon}>
                    <img alt="" src={require('../../assets/icons/ico-dashboard.svg')} />
                </Button>
            </NavLink>
            <NavLink activeClassName="selected" to={`${props.match.path}/reports`} className={classes.icon}>
                <Button className={classes.icon}>
                    <Icon>pie_chart</Icon>
                </Button>
            </NavLink>

            <NavLink activeClassName="selected" to={`${props.match.path}/stores`} className={classes.icon}>
                <Button className={classes.icon}>
                    <Icon>store</Icon>
                </Button>
            </NavLink>

            <NavLink activeClassName="selected" to={`${props.match.path}/users`} className={classes.icon}>
                <Button className={classes.icon}>
                    <Icon>person</Icon>
                </Button>
            </NavLink>
        </div>
    )
}


export default BottomNav
