import React, { useState } from 'react'
import { TableBody, TableHead, TableRow, TableCell } from '@material-ui/core'
import { updateData, postData, deleteData } from '../../utils'
import EditDialog from './EditDialog'
import { ENDPOINT } from '../../config'
import ScrollablePaperTable from '../lib/ScrollablePaperTable'

const StoreSchema = {
    name: "",
    description: "",
    storeKey: "",
    storeName: "",
    address: "",
    city: "",
    country: "",
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    latitude: "",
    longitude: ""
}

export default function Users({ stores, setStores }) {

    const [store, setStore] = useState({ ...StoreSchema })
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")

    const handleClick = (store) => {
        setStore(store)
        setShowEditDialog(true)
    }

    function handleCreate() {
        setStore({ ...StoreSchema })
        setShowEditDialog(true)
    }

    function updateStore(store) {
        setIsProcessing(true)
        updateData(ENDPOINT.STORES + '/' + store.id, store)
            .then(store => {
                setStores([
                    ...stores.map(st => {
                        return st.id === store.id ? store : st
                    })
                ])
                setIsProcessing(false)
                setShowEditDialog(false)
            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }
    function createStore(store) {
        setIsProcessing(true)
        postData(ENDPOINT.STORES, store)
            .then(store => {
                setStores([
                    ...stores,
                    store
                ])
                setIsProcessing(false)
                setShowEditDialog(false)

            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }
    function deleteStore(id) {
        setIsProcessing(true)
        deleteData(ENDPOINT.STORES + '/' + id, {}, false)
            .then(() => {
                setStores([
                    ...stores.filter(x => x.id !== id)
                ])
                setIsProcessing(false)
                setShowEditDialog(false)
            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }

    const filteredStores =
        stores.filter(x => [x.name, x.description, x.country, x.city, x.contactName, x.address].find(value => value && value.toLowerCase().includes(searchQuery)))

    return (
        <>
            <ScrollablePaperTable
                handleCreate={handleCreate}
                searchHint={'Search in Stores'}
                handleSearchQuery={(query) => setSearchQuery(query)}
                searchQuery={searchQuery}
                addLabel="Add Store"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Contact Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredStores.map((store) => (
                        <TableRow
                            hover
                            key={`store-${store.id}`}
                            onClick={() => handleClick(store)}
                        >
                            <TableCell>
                                {store.id}
                            </TableCell>
                            <TableCell>
                                {store.name}
                            </TableCell>
                            <TableCell>
                                {store.description}
                            </TableCell>
                            <TableCell>
                                {store.country}
                            </TableCell>
                            <TableCell>
                                {store.city}
                            </TableCell>
                            <TableCell>
                                {store.contactName}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </ScrollablePaperTable>

            <EditDialog
                onClose={() => setShowEditDialog(false)}
                store={store}
                open={showEditDialog}
                stores={stores}
                updateStore={updateStore}
                deleteStore={deleteStore}
                createStore={createStore}
                busy={isProcessing}
            >
            </EditDialog>
        </>
    )
}
