// PrivateRoute.js
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
export default function PrivateRoute({ component: Component, ...rest }) {
    return <Route {...rest} render={(props) => (
        // IMPORTANT: rest.permissions must be an array
        rest.permissions.includes(false) && !rest.isLoading
            ? <Redirect to={`${rest.redirectionPath || '/login'}`} />
            : <Component {...props} />
    )} />
}
