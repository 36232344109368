import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { percFormatter } from '../../utils'
import TableDoughnut from './TableDoughnut';
import MultiBar from './MultiBar';

const useStyles = makeStyles(({
    paper: {
        padding: 16,
    }
}));

export default function OrderHistory({
    useOrderTypes,
    useOrderTypesData,
}) {
    const classes = useStyles()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.gridIdem}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableDoughnut
                            formatter={percFormatter}
                            title="Payments per order type"
                            data={useOrderTypes.data}
                            tableHead={useOrderTypes.tableHead}
                            tableBody={useOrderTypes.tableBody}
                            labels={useOrderTypes.labels} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <MultiBar
                            title="Order Types Details"
                            // formatter={toCurrency}
                            data={useOrderTypesData.data}
                            labels={useOrderTypesData.labels} />
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}
