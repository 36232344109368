const primaryLight = {
  main: "#f4d53a",
  light: "#ffff6f",
  dark: "#bea400",
  contrastText: "#272F3D",
};
const primaryDark = {
  main: "#3af49a",
  light: "#7dffcb",
  dark: "#00c06b",
  contrastText: "#181D30"
};
const secondaryDark = {
  main: "#3af49a",
  light: "#7dffcb",
  dark: "#00c06b",
  contrastText: "#181D30"
};
const secondaryLight = {
  main: "#2c3249",
  light: "#565b75",
  dark: "#040a21",
  contrastText: "#fff"
};

const backgroundLight = {
  paper: "#F7F7F8",
  default: "#cfd8dc",
};
const backgroundDark = {
  // default: "#384354",
  // paper: "#1F2438",
};

const defaultColorDark = {
    main: "#2c2c2c",
    light: "#535353",
    dark: "#212121",
    contrastText: "#fff"
}
const defaultColorLight = {
    main: "#FCFCFC",
    light: "#ffffff",
    dark: "#ececec",
    contrastText: "#3A415B"
}

export { primaryLight, secondaryLight, primaryDark, secondaryDark, backgroundDark, backgroundLight, defaultColorLight, defaultColorDark };
