import React, { useState } from "react";
import { IconButton, Tooltip, Icon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
// import { connect } from 'react-redux';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { postData, getCredentials, hasAccess } from "../../utils";
import { BASE_URL, HOME_PAGE } from "../../config";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  colorPrimary: {
    margin: "0 8px",
    backgroundColor: "red !important"
  },
  icon: {
    color: "#2c3249"
  },
  menuItem: {
    padding: 8
  }
}));
const NavigationDrawer = props => {
  const classes = useStyles();
  const { setOpenEditProfile } = props;
  const user = getCredentials().user;

  const [isExpand, setExpand] = useState(false);

  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleEditProfileClick() {
    handleClose();
    setOpenEditProfile(true);
  }

  function logOut() {
    postData(BASE_URL + "/auth/logout")
      .then(() => {
        localStorage.removeItem(`pos_credentials${HOME_PAGE}`);
        window.location.reload();
      })
      .catch(ex => console.log(ex));
  }

  return (
    <div className={`navigationDrawer show-lg ${isExpand ? "expanded" : ""}`}>
      <ul>
        <Tooltip title="Account" placement="right">
          <li>
            <IconButton className={classes.icon} onClick={handleClick}>
              <Icon>account_circle</Icon>{" "}
              <span style={{ textTransform: "none" }} className="menu-title">
                {user.email}
              </span>
            </IconButton>
          </li>
        </Tooltip>
        <Tooltip title="Dashboard" placement="right">
          <li>
            <NavLink
              activeClassName="selected"
              to={`${props.match.path}/dashboard`}
            >
              <IconButton>
                <img
                  alt=""
                  src={require("../../assets/icons/ico-dashboard.svg")}
                />
                <span className="menu-title">Dashboard</span>
              </IconButton>
            </NavLink>
          </li>
        </Tooltip>
        <Tooltip title="Reports" placement="right">
          <li>
            <NavLink
                activeClassName="selected"
                to={`${props.match.path}/reports`}
            >
              <IconButton>
                <Icon>pie_chart</Icon>
                <span className="menu-title">Reports</span>
              </IconButton>
            </NavLink>
          </li>
        </Tooltip>
        {hasAccess("manageUsers") && (
          <Tooltip title="Stores" placement="right">
            <li>
              <NavLink
                activeClassName="selected"
                to={`${props.match.path}/stores`}
              >
                <IconButton className={classes.icon}>
                  <Icon>store</Icon>
                  <span className="menu-title">Stores</span>
                </IconButton>
              </NavLink>
            </li>
          </Tooltip>
        )}
        {hasAccess("manageUsers") && (
          <Tooltip title="Users" placement="right">
            <li>
              <NavLink
                activeClassName="selected"
                to={`${props.match.path}/users`}
              >
                <IconButton className={classes.icon}>
                  <Icon>person</Icon>
                  <span className="menu-title">Stores</span>
                </IconButton>
              </NavLink>
            </li>
          </Tooltip>
        )}
        <Tooltip title="Map" placement="right">
          <li>
            <NavLink activeClassName="selected" to={`${props.match.path}/map`}>
              <IconButton className={classes.icon}>
                <Icon>map</Icon>
                <span className="menu-title">Map</span>
              </IconButton>
            </NavLink>
          </li>
        </Tooltip>
      </ul>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: 200 } }}
      >
        <MenuItem onClick={logOut} className={classes.menuItem}>
          <Icon style={{ marginRight: 8 }} className={classes.icon}>
            arrow_right_alt
          </Icon>
          Logout
        </MenuItem>
        <MenuItem onClick={handleEditProfileClick} className={classes.menuItem}>
          <Icon style={{ marginRight: 8 }} className={classes.icon}>
            account_circle
          </Icon>
          Edit profile
        </MenuItem>
      </Menu>
      <ul className="menu-toggle">
        {!isExpand && (
          <li>
            <IconButton onClick={() => setExpand(true)}>
              <Icon className={classes.icon}>menu</Icon>
            </IconButton>
          </li>
        )}
        {isExpand && (
          <li>
            <IconButton onClick={() => setExpand(false)}>
              <Icon className={classes.icon}>keyboard_backspace</Icon>
            </IconButton>
          </li>
        )}
      </ul>
    </div>
  );
};

// const mapStateToProps = (state) => {
//     return {
//         context: state
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {

//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawer)
export default NavigationDrawer;
