import React, { useState } from 'react'
import { TableBody, TableHead, TableRow, TableCell } from '@material-ui/core'
import { updateData, postData, deleteData } from '../../utils'
import EditDialog from './EditDialog'
import { ENDPOINT } from '../../config'
import ScrollablePaperTable from '../lib/ScrollablePaperTable'
const md5 = require('md5');

const UserSchema = {
    fullname: "",
    username: "",
    email: "",
    isLocked: false,
    passwordDisplay: "",
    password: "",
    roleId: null
}

export default function Users({ users, setUsers, roles }) {

    const [user, setUser] = useState({ ...UserSchema })
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")

    const handleClick = (user) => {
        setUser(user)
        setShowEditDialog(true)
    }

    function handleCreate() {
        setUser({ ...UserSchema })
        setShowEditDialog(true)
    }

    function updateUser(user) {
        setIsProcessing(true)
        updateData(ENDPOINT.USERS + '/' + user.id, user)
            .then(user => {
                setUsers([
                    ...users.map(us => {
                        return us.id === user.id ? user : us
                    })
                ])
                setIsProcessing(false)
                setShowEditDialog(false)
            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }
    function createUser(user) {
        setIsProcessing(true)
        postData(ENDPOINT.USERS, { ...user, password: md5(user.passwordDisplay) })
            .then(user => {
                setUsers([
                    ...users,
                    user
                ])
                setIsProcessing(false)
                setShowEditDialog(false)
            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }
    function deleteUser(id) {
        setIsProcessing(true)
        deleteData(ENDPOINT.USERS + '/' + id, {}, false)
            .then(() => {
                setUsers([
                    ...users.filter(x => x.id !== id)
                ])
                setIsProcessing(false)
                setShowEditDialog(false)
            })
            .catch(ex => {
                setIsProcessing(false)
            })
    }

    const filteredUsers =
        users.filter(x => [x.fullname, x.username, x.email].find(value => value && value.toLowerCase().includes(searchQuery)))

    return (
        <>
            <ScrollablePaperTable
                handleCreate={handleCreate}
                searchHint={'Search in Users'}
                handleSearchQuery={(query) => setSearchQuery(query)}
                searchQuery={searchQuery}
                addLabel="Add User"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>User name</TableCell>
                        <TableCell>E-mail</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUsers.map((user) => (
                        <TableRow
                            hover
                            key={`user-${user.id}`}
                            onClick={() => handleClick(user)}
                        >
                            <TableCell>
                                {user.id}
                            </TableCell>
                            <TableCell>
                                {user.fullname}
                            </TableCell>
                            <TableCell>
                                {user.username}
                            </TableCell>
                            <TableCell>
                                {user.email}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </ScrollablePaperTable>

            <EditDialog
                onClose={() => setShowEditDialog(false)}
                user={user}
                open={showEditDialog}
                users={users}
                updateUser={updateUser}
                deleteUser={deleteUser}
                createUser={createUser}
                roles={roles}
                busy={isProcessing}
            >
            </EditDialog>
        </>
    )
}
