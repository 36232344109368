import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import Metrics from './Metrics'
import MultiBar from './MultiBar'
import TableBar from './TableBar'
import { toCurrency } from '../../utils'
import TableDoughnut from './TableDoughnut'
import { percFormatter } from '../../utils'

const useStyles = makeStyles(({
    paper: {
        padding: 16,
    }
}));

export default function Overview({
    metrics,
    useHourly,
    useTopItems,
    useMetricsData,
}) {
    const classes = useStyles()

    return (
      <>
        <Grid container spacing={2}>
          <Metrics metrics={metrics} />
          <Grid item xs={12}>
            <Paper className={[classes.paper].join(" ")}>
              <MultiBar
                title="Overview"
                formatter={toCurrency}
                data={useMetricsData.data}
                labels={useMetricsData.labels}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.gridIdem}>
            <Paper
              className={[classes.paper, classes.chartContainer].join(" ")}
            >
              <TableBar
                title="Orders per hour"
                data={useHourly.data}
                tableHead={useHourly.tableHead}
                tableBody={useHourly.tableBody}
                labels={useHourly.labels}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.gridIdem}>
            <Paper
              className={[classes.paper, classes.chartContainer].join(" ")}
            >
              <TableDoughnut
                formatter={percFormatter}
                title="Top selling items"
                data={useTopItems.data}
                tableHead={useTopItems.tableHead}
                tableBody={useTopItems.tableBody}
                labels={useTopItems.labels}
              />
            </Paper>
          </Grid>
        </Grid>
      </>
    );
}
