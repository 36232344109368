import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions } from '@material-ui/core';

export default props => {

  const {
    entityName,
    ...other
  } = props;

  return (
    <Dialog
      {...other}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete {entityName}?</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this {entityName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(false)}>
          No
        </Button>
        <Button onClick={() => props.onClose(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}