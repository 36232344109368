import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justify="center"
      style={{ height: 100 + "vh", backgroundColor: "white" }}
    >
      <Grid item>
        <img
          alt="logo"
          src={require("../../assets/images/logo.png")}
          width="40"
          style={{ margin: 16 }}
        />
      </Grid>
      <Grid item style={{ width: 150 }}>
        <div className={classes.root}>
          <LinearProgress color={"secondary"} />
        </div>
      </Grid>
    </Grid>
  );
}
