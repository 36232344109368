import React, { useState, useEffect } from 'react';
import EditDialogBase from '../lib/EditDialogBase';
import {
    TextField, Grid, FormControlLabel, Checkbox
} from "@material-ui/core";
import SelectableOptions from "../lib/SelectableOptions";

export default props => {
    const {
        onClose,
        user,
        context,
        users,
        updateUser,
        deleteUser,
        createUser,
        busy,
        roles,
        ...other
    } = props;
    const [entity, setEntity] = useState({ ...user });

    useEffect(() => {
        setEntity({ ...user });
    }, [user]);

    const handleChange = name => event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setEntity({ ...entity, [name]: value });
        // if (name === 'passwordDisplay') {
        //     const password = md5(value)
        //     setEntity({ ...entity, password })
        // }
    }

    const updateUserLocal = (entity) => {
        const { username, ...other } = entity;
        return updateUser(other);
    }

    return (
        <EditDialogBase
            onClose={onClose}
            entity={entity}
            entityName="user"
            deleteEntity={deleteUser}
            updateEntity={updateUserLocal}
            createEntity={createUser}
            busy={busy}
            {...other}
        >
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="user-fullname"
                        value={entity.fullname}
                        onChange={handleChange('fullname')}
                        label="Full name"
                        variant="filled"
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <TextField
                        type="email"
                        disabled={busy}
                        className="w-100"
                        id="user-email"
                        value={entity.email}
                        onChange={handleChange('email')}
                        label="Email"
                        variant="filled"
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        disabled={entity.id > 0}
                        className="w-100"
                        id="user-name"
                        value={entity.username}
                        onChange={handleChange('username')}
                        label="User name"
                        variant="filled"
                    />
                </Grid>
                {entity && !entity.id &&
                    <Grid item sm={6} xs={12}>
                        <TextField
                            type="password"
                            disabled={busy}
                            className="w-100"
                            id="user-password"
                            value={entity.passwordDisplay}
                            onChange={handleChange('passwordDisplay')}
                            label="Password"
                            variant="filled"
                        />
                    </Grid>}
                <Grid item sm={6} xs={12}>
                    <SelectableOptions
                        className="w-100"
                        controlId="user-role"
                        label="Select role"
                        hasNone={false}
                        busy={busy}
                        value={entity.roleId}
                        values={roles}
                        handleChange={handleChange('roleId')}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                disabled={busy}
                                checked={entity.isLocked}
                                onChange={handleChange('isLocked')}
                                value="isLocked" />
                        }
                        label="Is Locked"
                    />
                </Grid>
            </Grid>
        </EditDialogBase>
    )
}
