import React, { useState, useEffect } from 'react';
import EditDialogBase from '../lib/EditDialogBase';
import { TextField, Grid } from "@material-ui/core";

export default props => {
    const {
        onClose,
        store,
        context,
        users,
        updateStore,
        deleteStore,
        createStore,
        busy,
        ...other
    } = props;
    const [entity, setEntity] = useState({ ...store });

    useEffect(() => {
        setEntity({ ...store });
    }, [store]);

    const handleChange = name => event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setEntity({ ...entity, [name]: value });
    }

    const updateStoreLocal = (entity) => {
        const { ...other } = entity;
        return updateStore(other);
    }

    return (
        <EditDialogBase
            onClose={onClose}
            entity={entity}
            maxWidth={"md"}
            entityName="store"
            deleteEntity={deleteStore}
            updateEntity={updateStoreLocal}
            createEntity={createStore}
            busy={busy}
            {...other}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-name"
                        value={entity.name}
                        onChange={handleChange('name')}
                        label="Name"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-name"
                        value={entity.storeName}
                        onChange={handleChange('storeName')}
                        label="Store name"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-key"
                        value={entity.storeKey}
                        onChange={handleChange('storeKey')}
                        label="Key"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        multiline={true}
                        rows="4"
                        id="store-description"
                        value={entity.description}
                        onChange={handleChange('description')}
                        label="Description"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-country"
                        value={entity.country}
                        onChange={handleChange('country')}
                        label="Country"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-city"
                        value={entity.city}
                        onChange={handleChange('city')}
                        label="City"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-address"
                        value={entity.address}
                        onChange={handleChange('address')}
                        label="Address"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-city"
                        value={entity.contactName}
                        onChange={handleChange('contactName')}
                        label="Contact Name"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-contactEmail"
                        value={entity.contactEmail}
                        onChange={handleChange('contactEmail')}
                        label="Email"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-address"
                        value={entity.contactPhone}
                        onChange={handleChange('contactPhone')}
                        label="Phone"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-latitude"
                        value={entity.latitude}
                        onChange={handleChange('latitude')}
                        label="Latitude"
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={busy}
                        className="w-100"
                        id="store-longitude"
                        value={entity.longitude}
                        onChange={handleChange('longitude')}
                        label="Longitude"
                        variant="filled"
                    />
                </Grid>
            </Grid>
        </EditDialogBase>
    )
}
