import React, { Fragment, useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { toCurrency } from "../../utils";
import Moment from "react-moment";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  paper: {
    margin: "4px",
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  headerData: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "11px",
    opacity: 0.5
  },
  commonCell: {
    lineHeight: 1.5
  },
  title: {
    padding: "0 16px",
    fontSize: "12px",
    opacity: 0.5,
    textTransform: "uppercase",
    letterSpacing: "1px",
    height: "56px",
    lineHeight: "56px"
  },
  cellBold: {
    fontWeight: "bold" //#181D30""
  },
  button: {
    // Repeated Style
    textTransform: "none",
    fontFamily: "Roboto Condensed",
    marginRight: 8
  },
  scrollableContainer: {
    flexGrow: 1,
    overflow: "auto"
  },
  cellName: {
    fontFamily: "Roboto Condensed!important",
    fontSize: "16px"
  }
});

const safeValue = (shift, prop, currency) =>
  shift ? (currency ? toCurrency(shift[prop]) : shift[prop]) : "";

const Waiters = props => {
  const classes = useStyles();
  const { userReports, setUser, setOpen } = props;
  const [showAll, setShowAll] = useState(false);

  const onWaiterClick = waiter => {
    setOpen(true);
    setUser(waiter);
  };

  return (
    <Fragment>
      <Grid container justify="space-between" alignItems="center">
        <div className={classes.title}>
          {showAll ? "All Users" : "Clocked in Users"}
        </div>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show clocked in" : "Show all"}
        </Button>
      </Grid>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              className={[classes.commonCell, classes.headerData].join(" ")}
            >
              Waiter name
            </TableCell>
            <TableCell
              className={[classes.commonCell, classes.headerData].join(" ")}
            >
              Orders
            </TableCell>
            <TableCell
              className={[classes.commonCell, classes.headerData].join(" ")}
            >
              Gross
            </TableCell>
            <TableCell
              className={[classes.commonCell, classes.headerData].join(" ")}
            >
              {"Discounts & Treats"}
            </TableCell>
            <TableCell
              className={[classes.commonCell, classes.headerData].join(" ")}
            >
              Clocked In
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {userReports
            .filter(user => (!showAll ? user.isCheckedIn : user))
            .map(userReport => {
              const unwrapShift = (prop, currency) => {
                const value = safeValue(userReport.shiftMetrics, prop);
                return currency ? toCurrency(value) : value;
              };

              return (
                <TableRow
                  hover
                  key={userReport.userId}
                  onClick={() => onWaiterClick(userReport)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell
                    className={[classes.cellName, classes.commonCell].join(" ")}
                  >
                    {safeValue(userReport, "userDisplayName")}
                  </TableCell>
                  <TableCell
                    className={[classes.commonCell, classes.cell].join(" ")}
                  >
                    {unwrapShift("orderCount")}
                  </TableCell>
                  <TableCell
                    className={[classes.commonCell, classes.cell].join(" ")}
                  >
                    {unwrapShift("totalAmount", true)}
                  </TableCell>
                  <TableCell
                    className={[classes.commonCell, classes.cell].join(" ")}
                  >
                    {unwrapShift("offerAmount", true)}
                  </TableCell>
                  <TableCell
                    className={[classes.commonCell, classes.cell].join(" ")}
                  >
                    {userReport.shiftMetrics.checkInAt ? (
                      <Moment fromNow>
                        {userReport.shiftMetrics.checkInAt}
                      </Moment>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {/*<UserOverview*/}
      {/*    history={props.history}*/}
      {/*    workDate={workDate}*/}
      {/*    open={showModal}*/}
      {/*    handleClose={() => setShowModal(false)}*/}
      {/*    userId={waiter && waiter.userId} />*/}
    </Fragment>
  );
};

export default Waiters;
