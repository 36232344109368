import React from 'react'
import OrderView from './OrderView'
import { TablePagination, Grid } from '@material-ui/core';

export default function OrderHistory({ ordersData, setCurrentOrderPage, currentPage, stores }) {
    return (
        <>
            <OrderView
                orders={ordersData.content}
                stores={stores}
            />
            <Grid container>
                <Grid item flex={1} container>
                    <TablePagination
                        className="w-100"
                        count={ordersData.totalElements}
                        onChangePage={(obj, page) => setCurrentOrderPage(page)}
                        page={currentPage}
                        rowsPerPage={ordersData.size}
                        rowsPerPageOptions={[ordersData.size]}
                    />
                </Grid>
            </Grid>
        </>
    )
}
