import React, { useCallback, useEffect, useState } from "react";
import NavigationDrawer from "./NavigationDrawer";
import { Redirect, Route } from "react-router-dom";
import Reports from "../Reports";
import Dashboard from "../Dashboard";
import { fetchData, hasAccess } from "../../utils";
import { ENDPOINT } from "../../config";
import Users from "../Users";
import Stores from "../Stores";
import BottomNav from "./BottomNav";
import { Grid } from "@material-ui/core";
import AppBarNav from "./AppBarNav";
import Maps from "../Reports/Maps";
import PrivateRoute from "../lib/PrivateRoute";
import EditProfile from "./EditProfile";

export default function Origin(props) {
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [roles, setRoles] = useState([]);
  const [liveStores, setLiveStores] = useState([]);
  const [openEditProfile, setOpenEditProfile] = useState(false);

  const fetchUsers = useCallback(() => {
    fetchData(ENDPOINT.USERS)
      .then(users => setUsers(users))
      .catch(ex => console.log(ex));
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchData(ENDPOINT.STORES + "/workdate/latest")
      .then(data => setLiveStores(data))
      .catch(ex => console.log(ex));
    fetchData(ENDPOINT.STORES)
      .then(stores => setStores(stores))
      .catch(ex => console.log(ex));

    fetchData(ENDPOINT.ROLES)
      .then(data => setRoles(data))
      .catch(ex => console.log(ex));
  }, [fetchUsers]);

  const GEOStores = React.useMemo(() => {
    const getStores = stores.filter(x => x.latitude && x.longitude);

    const center = stores[0] ? [stores[0].latitude, stores[0].longitude] : null;
    return {
      stores: getStores,
      center: center
    };
  }, [stores]);

  return (
    <div>
      <NavigationDrawer {...props} setOpenEditProfile={setOpenEditProfile} />
      <AppBarNav {...props} />
      <BottomNav {...props} />
      <div className="main-content">
        <Grid direction="column" spacing={2}>
          <Route
            exact
            path={`${props.match.path}`}
            render={() => <Redirect to={`${props.match.path}/dashboard`} />}
          />
          <Route
            {...props}
            path={`${props.match.path}/dashboard`}
            component={() => <Dashboard stores={liveStores} />}
          />
          <Route
            {...props}
            path={`${props.match.path}/reports`}
            render={props => <Reports stores={stores} {...props} />}
          />
          <PrivateRoute
            permissions={[hasAccess("manageUsers")]}
            redirectionPath={`${props.match.path}/dashboard`}
            {...props}
            path={`${props.match.path}/users`}
            component={() => (
              <Users users={users} setUsers={setUsers} roles={roles} />
            )}
          />
          <PrivateRoute
            permissions={[hasAccess("manageStores")]}
            redirectionPath={`${props.match.path}/dashboard`}
            {...props}
            path={`${props.match.path}/stores`}
            component={() => <Stores stores={stores} setStores={setStores} />}
          />
          <Route
            path={`${props.match.path}/map`}
            render={() => (
              <Maps stores={GEOStores.stores} position={GEOStores.center} />
            )}
          />
        </Grid>
      </div>
      <EditProfile
        open={openEditProfile}
        users={users}
        fetchUsers={fetchUsers}
        handleClose={() => setOpenEditProfile(false)}
      />
    </div>
  );
}
