import * as colors from "./colors";
import {createMuiTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import React from "react";

const overrides = prefersDarkMode => {
  const btnDefault = prefersDarkMode
    ? colors.defaultColorDark
    : colors.defaultColorLight;

  const btnPrimary = prefersDarkMode ? colors.primaryDark : colors.primaryLight;
  return {
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiFilledInput: {
      underline: {
        borderRadius: 10
      }
    },
    // MuiBadge: {
    //   colorPrimary: {
    //     backgroundColor: 'red',
    //   },
    // },
    MuiListItem: {
      root: {
        "&$selected": {
          color: btnPrimary.contrastText,
          backgroundColor: btnPrimary.main,
          "&:hover": {
            backgroundColor: btnPrimary.dark
          }
        }
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: btnDefault.main,
        color: btnDefault.contrastText,
        "&:hover": {
          backgroundColor: btnDefault.dark
        }
      },
      label: {
        fontFamily: "Roboto Condensed, sans-serif"
      }
    }
  };
};

const typography = prefersDarkMode => {
  const btnPrimary = prefersDarkMode ? colors.primaryDark : colors.primaryLight;
  return {
    root: {
      fontFamily: "Roboto Condensed"
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    caption: {
      color: prefersDarkMode ? btnPrimary.main : "#163582"
    }
  };
};

function useAppTheme() {
  const prefersDarkMode = false
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: prefersDarkMode ? colors.primaryDark : colors.primaryLight,
          secondary: prefersDarkMode
            ? colors.secondaryDark
            : colors.secondaryLight,
          background: prefersDarkMode
            ? colors.backgroundDark
            : colors.backgroundLight
        },
        overrides: overrides(prefersDarkMode),
        typography: typography(prefersDarkMode)
      }),
    [prefersDarkMode]
  );
}

export { colors, useAppTheme };
