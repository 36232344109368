// Names
const APP_NAME = "Snapserve";
const KEY_DINE_IN = "Dine in";
const KEY_TAKEAWAY = "Take out";
const KEY_CATERING = "Catering";
const KEY_DELIVERY = "Delivery";

// const MENU_
// Texts
const TOOLTIP_WAITER_SHIFT_DURATION = "Time worked without excluding breaks.";
const LABEL_WAITER_SHIFT_DURATION = "TIME WORKED.";
const TOOLTIP_WAITER_DAILY_PAY = "Time worked without excluding breaks.";
const LABEL_WAITER_DAILY_PAY = "SHIFT WAGE";
const TOOLTIP_WAITER_TOTAL_BREAK_DURATION =
  "Total time duration the employee was on a break";
const LABEL_WAITER_TOTAL_BREAK_DURATION = "BREAKS";
const TOOLTIP_WAITER_ORDER_COUNT =
  "Total number of orders opened (including canceled, refunded, etc)";
const LABEL_WAITER_ORDER_COUNT = "TOTAL ORDERS";
const TOOLTIP_WAITER_OPEN_ORDERS =
  "Orders that are still not closed (including orders that still await tips)";
const LABEL_WAITER_OPEN_ORDERS = "OPEN ORDERS";
const TOOLTIP_WAITER_TOTAL_GROSS =
  "Gross sales (excluding refunds and cancelled orders)";
const LABEL_WAITER_TOTAL_GROSS = "SALES (GROSS)";
const TOOLTIP_WAITER_TOTAL_NET =
  "Net sales (excluding refunds and cancelled orders)";
const LABEL_WAITER_TOTAL_NET = "SALES (NET)";
const TOOLTIP_WAITER_TOTAL_OFFER = "Total amount of treats and discount";
const LABEL_WAITER_TOTAL_OFFER = "TOTAL OFFERS";
const TOOLTIP_WAITER_CHECK_IN_CASH =
  "Total cash amount that the employee receives at the beginning of his/het shift";
const LABEL_WAITER_CHECK_IN_CASH = "CASH ON CLOCK IN";
const TOOLTIP_WAITER_CHECK_OUT_CASH =
  "Total cash amount that the employee ends up with at the end of his/her shift";
const LABEL_WAITER_CHECK_OUT_CASH = "CASH ON CLOCK OUT";
const TOOLTIP_WAITER_CHECK_IN_AT =
  "Time that the employee starts his/her shift";
const LABEL_WAITER_CHECK_IN_AT = "CLOCK IN AT";
const TOOLTIP_METRIC_ORDERS_COUNT =
  "Total number of orders opened (including canceled, refunded, etc)";
const LABEL_METRIC_ORDERS_COUNT = "TOTAL ORDERS";
const TOOLTIP_METRIC_ORDERS_CANCEL_COUNT =
  "Orders that were opened but cancelled before completion of payment (can include orders that were partially paid)";
const LABEL_METRIC_ORDERS_CANCEL_COUNT = "CANCELLED ORDERS";
const TOOLTIP_METRIC_NET_AMOUNT =
  "Net sales (excluding refunds and cancelled orders)";
const LABEL_METRIC_NET_AMOUNT = "TOTAL SALES (NET)";
const TOOLTIP_METRIC_GROSS_AMOUNT =
  "Gross sales (excluding refunds and cancelled orders)";
const LABEL_METRIC_GROSS_AMOUNT = "TOTAL SALES (GROSS)";
const TOOLTIP_METRIC_VAT_AMOUNT =
  "Total sales tax calculated as the sum of tax per item";
const LABEL_METRIC_VAT_AMOUNT = "TOTAL SALES TAX";
const TOOLTIP_METRIC_TOTAL_DISCOUNT = "Total discount";
const LABEL_METRIC_TOTAL_DISCOUNT = "TOTAL DISCOUNT";
const TOOLTIP_METRIC_TOTAL_TREATS =
  "Total amounts that were given free of charge";
const LABEL_METRIC_TOTAL_TREATS = "TOTAL TREATS";
const TOOLTIP_METRIC_TOTAL_CASH =
  "Total cash payments excluding refunds and tips";
const LABEL_METRIC_TOTAL_CASH = "TOTAL CASH";
const TOOLTIP_METRIC_TOTAL_CREDIT =
  "Total credit payments excluding refunds and tips";
const LABEL_METRIC_TOTAL_CREDIT = "TOTAL CREDIT CARDS";

// Elements

// Messages
const FAIL_DEFAULT = "Something went wrong";
const FAIL_AUTH_GET_STATUS = "Failed to get user status";
const FAIL_AUTH_CLOCK_IN = "Failed to clock in";
const FAIL_AUTH_CLOCK_OUT = "Failed to clock out";
const FAIL_AUTH_START_BREAK = "Failed to start break";
const FAIL_AUTH_END_BREAK = "Failed to end break";
const FAIL_AUTH_UPDATE_CREDENTIALS = "Failed to update user credentials";
const FAIL_AUTH_REQUEST_REJECT = "Request rejected";
const FAIL_ORDER_UPDATE = "Failed to update order";
const FAIL_ORDER_SPLIT = "Failed to split order";
const FAIL_ORDER_SPLIT_RESET = "Failed to reset split";
const FAIL_ORDER_CREATE_TABLE = "Failed to create table order";
const FAIL_ORDER_CREATE = "Failed to create order";
const FAIL_ORDER_GET_PAYMENTS = "Failed to load order payments";
const FAIL_ORDER_GET_OPEN_TABLES = "Failed to load open tables";
const FAIL_ORDER_GET_OPEN_DELIVERIES = "Failed to load open deliveries";
const FAIL_ORDER_GET_OPEN_ORDERS = "Failed to load open orders";
const FAIL_ORDER_GET_ALL_WITH_INFO =
  "Failed to load orders with full information";
const FAIL_ORDER_LOAD = "Failed to load order";
const FAIL_ORDER_PRINT = "Failed to print order";
const FAIL_ORDER_ADD_ITEMS = "Failed to add items in order";
const FAIL_ORDER_PRINT_RECEIPT = "Failed to print receipt";
const FAIL_ORDER_DISPATCH = "Failed to dispatch order";
const FAIL_ORDER_DELIVER = "Failed to deliver order";
const FAIL_ORDER_CANCEL = "Failed to cancel order";
const FAIL_ORDER_DELETE_ITEM = "Failed to delete item";
const FAIL_SETUP_OPTION_UPDATE = "Failed to update option";
const FAIL_SETUP_OPTION_LOAD = "Failed to load options";
const FAIL_SETUP_OPTION_OPEN_WORK_DATE = "Failed to open work date";
const FAIL_SETUP_OPTION_CLOSE_WORK_DATE = "Failed to close work date";
const FAIL_SETUP_OPTION_GET_WORK_DATE = "Failed to retrieve current work date";

const SUCCESS_AUTH_REQUEST_APPROVE = "Request approved";
const SUCCESS_AUTH_UPDATE_PROFILE = "User profile updated";

export {
  FAIL_DEFAULT,
  FAIL_AUTH_GET_STATUS,
  FAIL_AUTH_CLOCK_IN,
  FAIL_AUTH_CLOCK_OUT,
  FAIL_AUTH_START_BREAK,
  FAIL_AUTH_END_BREAK,
  FAIL_AUTH_UPDATE_CREDENTIALS,
  FAIL_AUTH_REQUEST_REJECT,
  FAIL_ORDER_UPDATE,
  FAIL_ORDER_SPLIT,
  FAIL_ORDER_SPLIT_RESET,
  FAIL_ORDER_CREATE_TABLE,
  FAIL_ORDER_GET_PAYMENTS,
  FAIL_ORDER_GET_OPEN_TABLES,
  FAIL_ORDER_GET_OPEN_DELIVERIES,
  FAIL_ORDER_GET_OPEN_ORDERS,
  FAIL_ORDER_GET_ALL_WITH_INFO,
  FAIL_ORDER_LOAD,
  FAIL_ORDER_PRINT,
  FAIL_ORDER_ADD_ITEMS,
  FAIL_ORDER_CREATE,
  FAIL_ORDER_PRINT_RECEIPT,
  FAIL_ORDER_DISPATCH,
  FAIL_ORDER_DELIVER,
  FAIL_ORDER_CANCEL,
  FAIL_ORDER_DELETE_ITEM,
  FAIL_SETUP_OPTION_UPDATE,
  FAIL_SETUP_OPTION_LOAD,
  FAIL_SETUP_OPTION_OPEN_WORK_DATE,
  FAIL_SETUP_OPTION_CLOSE_WORK_DATE,
  FAIL_SETUP_OPTION_GET_WORK_DATE,
  SUCCESS_AUTH_REQUEST_APPROVE,
  SUCCESS_AUTH_UPDATE_PROFILE,
  //  Key strings
  APP_NAME,
  KEY_DINE_IN,
  KEY_TAKEAWAY,
  KEY_CATERING,
  KEY_DELIVERY,
  //  Metrics
  TOOLTIP_WAITER_SHIFT_DURATION,
  LABEL_WAITER_SHIFT_DURATION,
  TOOLTIP_WAITER_DAILY_PAY,
  LABEL_WAITER_DAILY_PAY,
  TOOLTIP_WAITER_TOTAL_BREAK_DURATION,
  LABEL_WAITER_TOTAL_BREAK_DURATION,
  TOOLTIP_WAITER_ORDER_COUNT,
  LABEL_WAITER_ORDER_COUNT,
  LABEL_WAITER_OPEN_ORDERS,
  TOOLTIP_WAITER_OPEN_ORDERS,
  TOOLTIP_WAITER_TOTAL_GROSS,
  LABEL_WAITER_TOTAL_GROSS,
  TOOLTIP_WAITER_TOTAL_NET,
  LABEL_WAITER_TOTAL_NET,
  TOOLTIP_WAITER_TOTAL_OFFER,
  LABEL_WAITER_TOTAL_OFFER,
  TOOLTIP_WAITER_CHECK_IN_CASH,
  LABEL_WAITER_CHECK_IN_CASH,
  TOOLTIP_WAITER_CHECK_OUT_CASH,
  LABEL_WAITER_CHECK_OUT_CASH,
  TOOLTIP_WAITER_CHECK_IN_AT,
  LABEL_WAITER_CHECK_IN_AT,
  TOOLTIP_METRIC_ORDERS_COUNT,
  LABEL_METRIC_ORDERS_COUNT,
  TOOLTIP_METRIC_ORDERS_CANCEL_COUNT,
  LABEL_METRIC_ORDERS_CANCEL_COUNT,
  TOOLTIP_METRIC_NET_AMOUNT,
  LABEL_METRIC_NET_AMOUNT,
  TOOLTIP_METRIC_GROSS_AMOUNT,
  LABEL_METRIC_GROSS_AMOUNT,
  TOOLTIP_METRIC_VAT_AMOUNT,
  LABEL_METRIC_VAT_AMOUNT,
  TOOLTIP_METRIC_TOTAL_DISCOUNT,
  LABEL_METRIC_TOTAL_DISCOUNT,
  TOOLTIP_METRIC_TOTAL_TREATS,
  LABEL_METRIC_TOTAL_TREATS,
  TOOLTIP_METRIC_TOTAL_CASH,
  LABEL_METRIC_TOTAL_CASH,
  TOOLTIP_METRIC_TOTAL_CREDIT,
  LABEL_METRIC_TOTAL_CREDIT
};
