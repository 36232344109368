import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import TableBar from './TableBar'
import {timeConvert} from "../../utils";

const useStyles = makeStyles(({
    paper: {
        padding: 16,
    }
}));

export default function UserReports({ useWorkingHoursData }) {
    const classes = useStyles()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={[classes.paper, classes.chartContainer].join(" ")}>
                        <TableBar
                            title="Working hours"
                            formatter={(val) => timeConvert(val * 60)}
                            data={useWorkingHoursData.data}
                            tableHead={useWorkingHoursData.tableHead}
                            tableBody={useWorkingHoursData.tableBody}
                            labels={useWorkingHoursData.labels} />
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}
