import React from 'react'
import {InfoPanel} from '../lib/Panel'
import { Box, Grid } from '@material-ui/core'
import { toCurrency, unwrap } from '../../utils'


const types = {
    currency: "currency",
    date: "date"
}

function safeUnrwap(obj, prop, type) {
    const value = unwrap(obj, prop)
    switch (type) {
        case types.currency:
            return value ? toCurrency(value) : toCurrency(0)
        case types.date:
            return value ? value : "N/A"
        default:
            return value ? value : "-"
    }
}

export default function Kpis({ kpis }) {
    return (
        <Grid container>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Daily Customers" value={safeUnrwap(kpis, "dailyCustomers")} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={200}><InfoPanel label="Daily Mean Per Receipt" value={safeUnrwap(kpis, "dailyMeanValuePerReceipt", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Daily Sales" value={safeUnrwap(kpis, "dailySales", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={180}><InfoPanel label="Monthly Customers" value={safeUnrwap(kpis, "monthlyCustomers")} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={250}><InfoPanel label="Monthly Mean Value Per Receipt" value={safeUnrwap(kpis, "monthlyMeanValuePerReceipt", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Monthly Sales" value={safeUnrwap(kpis, "monthlySales", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Weekly Sales" value={safeUnrwap(kpis, "weeklySales", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Yearly Sales" value={safeUnrwap(kpis, "yearlySales", types.currency)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Last Sale Date" value={safeUnrwap(kpis, "lastSaleDate", types.date)} textColor="#000" color="#fff" /></Box>
            <Box flexGrow={1} flexBasis={150}><InfoPanel label="Last Sale Hour" value={safeUnrwap(kpis, "lastSaleHour", types.date)} textColor="#000" color="#fff" /></Box>
        </Grid>
    )
}
