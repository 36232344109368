import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import BusyIndicator from "./BusyIndicator";
import {DialogContentText, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh"
  }
}));
const UIDialog = props => {
  const classes = useStyles();

  const {
    title,
    handleClose,
    open,
    actionButtons,
    primaryAction,
    primaryTitle,
    secondaryAction,
    secondaryTitle,
    maxWidth,
    fullWidth,
    busy,
    isValid,
    hint,
    contentPadding,
    fullHeight,
    tabDialog,
    description
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: fullHeight && classes.dialogPaper }}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <BusyIndicator busy={busy} />
      {tabDialog ? (
        props.children
      ) : (
        <DialogContent style={{ padding: contentPadding }}>
          {description && <DialogContentText>{description}</DialogContentText>}
          <BusyIndicator busy={busy} />
          {props.children}
        </DialogContent>
      )}
      <DialogActions>
        {actionButtons ? (
          actionButtons
        ) : (
          <>
            {hint && (
              <Typography
                style={{ marginRight: "auto" }}
                color={"textSecondary"}
                variant={"body2"}
              >
                {hint}
              </Typography>
            )}
            {secondaryAction && (
              <Button size={"large"} onClick={secondaryAction}>
                {secondaryTitle}
              </Button>
            )}
            {primaryAction && (
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={primaryAction}
                disabled={busy || !isValid}
                size={"large"}
                autoFocus
              >
                {primaryTitle}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UIDialog;

UIDialog.defaultProps = {
  title: "Notification",
  primaryTitle: "Confirm",
  secondaryTitle: "Close",
  maxWidth: "sm",
  description: "",
  fullWidth: false,
  busy: false,
  isValid: true,
  contentPadding: [8, 24],
  fullHeight: false,
  tabDialog: false
};

UIDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  actionButtons: PropTypes.element,
  primaryAction: PropTypes.func,
  primaryTitle: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryTitle: PropTypes.string,
  maxWidth: PropTypes.oneOf(["md", "sm", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  busy: PropTypes.bool,
  isValid: PropTypes.bool,
  contentPadding: PropTypes.any,
  fullHeight: PropTypes.bool,
  tabDialog: PropTypes.bool
};
